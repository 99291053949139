var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"min-h-screen bg-ashes-classic flex flex-col items-center justify-center gap-10"},[_vm._m(0),_c('img',{staticClass:"w-1/3 md:w-1/5",attrs:{"src":require("../assets/images/logotipo-previo.svg"),"alt":"Logotipo PReVIO"}}),_c('div',{staticClass:"flex flex-col justify-center items-center"},[_c('div',{staticClass:"font-medium"},[_vm._v("Por favor, selecione um projeto:")]),_c('div',{staticClass:"list-projects grid grid-cols-2 md:grid-cols-4 gap-4 items-stretch"},[_c('router-link',{attrs:{"to":"/admin"}},[_c('button',{staticClass:"card-project"},[_c('img',{attrs:{"src":require("../assets/images/logo.png"),"alt":"Logotipo Projeto"}})])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"back-button",attrs:{"href":"/"}},[_c('span',{staticClass:"material-icons"},[_c('span',{staticClass:"material-icons"},[_vm._v(" keyboard_backspace ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"card-project opacity-40",attrs:{"type":"button"}},[_c('img',{attrs:{"src":require("../assets/images/logo-intinerarte.png"),"alt":"Logotipo Projeto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"card-project opacity-40",attrs:{"type":"button"}},[_c('img',{attrs:{"src":require("../assets/images/logo-empodera.png"),"alt":"Logotipo Projeto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"card-project opacity-40",attrs:{"type":"button"}},[_c('img',{attrs:{"src":require("../assets/images/logo-projema.png"),"alt":"Logotipo Projeto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"card-project opacity-40",attrs:{"type":"button"}},[_c('img',{attrs:{"src":require("../assets/images/logo-jovens-mediadores.png"),"alt":"Logotipo Projeto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"card-project opacity-40",attrs:{"type":"button"}},[_c('img',{attrs:{"src":require("../assets/images/logo-napaz.png"),"alt":"Logotipo Projeto"}})])
}]

export { render, staticRenderFns }