import {
  createCity,
  destroyCity,
  getCities,
  getCitiesForMenus,
  getCityById,
  updateCity,
} from "../../service/CityApi";
import Vue from "vue";

const state = {
  city_fields: {
    id: null,
    name: null,
    state_id: null,
    neighborhoods_attributes: [],
  },
  neighborhoods_names: [],
  neighborhood_area: null,
  city: null,
  cityId: null,
  state: null,
  cities: null,
  citiesAll: null,
  errorMessages: [],
  modalToggleAddCity: false,
  modalToggleDeleteCity: false,
  modalToggleViewCity: false,
  modalToggleExportCitiesSpreadsheet: false,
  citiesTotal: 0,
  citiesTotalPerPage: 10,
  citiesOffset: 0,
  params: {
    name: null,
    state: null,
  },
};

const mutations = {
  setCitiesMutation: (state, cities) => {
    state.cities = cities;
  },
  setCitiesAllMutation: (state, cities) => {
    state.citiesAll = cities;
  },
  setCityMutation: (state, city) => {
    state.city = city;
  },
  setIdMutation: (state, id) => {
    state.city_fields.id = id;
  },
  setNameMutation: (state, name) => {
    state.city_fields.name = name;
  },
  setNeighborhoodsAttributesMutation: (state, payload) => {
    state.city_fields.neighborhoods_attributes = payload;
  },
  setNeighborhoodsNamesMutation: (state, payload) => {
    state.neighborhoods_names = payload;
  },
  setNeighborhoodAreaMutation: (state, payload) => {
    state.neighborhood_area = payload;
  },
  setStateMutation: (state, value) => {
    state.state = value;
    state.city_fields.state_id = value ? value.id : null;
  },
  // --> MUTATIONS - DELETION <--
  cityForDeletion: (state, payload) => {
    if (payload) {
      state.cityId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterNameMutation: (state, name) => {
    state.params.name = name;
  },
  setFilterStateMutation: (state, value) => {
    state.params.state = value;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setCitiesTotalPerPage: (state, citiesTotalPerPage) => {
    state.citiesTotalPerPage = citiesTotalPerPage;
  },
  setCitiesOffset: (state, citiesOffset) => {
    state.citiesOffset = citiesOffset;
  },
  setCitiesTotal: (state, citiesTotal) => {
    state.citiesTotal = citiesTotal;
  },
  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddCity: (state) => {
    state.modalToggleAddCity = !state.modalToggleAddCity;
  },
  toggleModalDeleteCity: (state) => {
    state.modalToggleDeleteCity = !state.modalToggleDeleteCity;
  },
  toggleModalViewCity: (state) => {
    state.modalToggleViewCity = !state.modalToggleViewCity;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    for (const prop in state.city_fields) {
      state.city_fields[prop] = null;
    }
    state.city_fields.neighborhoods_attributes = [];
    state.city = null;
    state.state = null;
    state.neighborhoods_names = [];
    state.neighborhood_area = [];
  },
  clearQuery: (state) => {
    state.params.name = null;
    state.params.state = null;
    state.cities = [];
  },

  // --> MUTATIONS - ERRORS <--
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

function getFilterParams() {
  return {
    name: state.params.name,
    state_id: state.params.state ? state.params.state.id : null,
    per_page: state.citiesTotalPerPage,
    offset: state.citiesOffset,
  };
}

// function fillNeighborhoodAttributes(commit) {
//   commit("setNeighborhoodsAttributesMutation", []);
//   let neighborhoods_attributes = [];
//   if (state.neighborhoods_names.length > 0) {
//     state.neighborhoods_names.forEach((neighborhoodName) => {
//       const neighborhood = {
//         id: null,
//         name: neighborhoodName,
//         area_id: state.neighborhood_area ? state.neighborhood_area.id : null,
//       };
//       neighborhoods_attributes.push(neighborhood);
//     });
//     commit("setNeighborhoodsAttributesMutation", neighborhoods_attributes);
//   }
// }

const actions = {
  loadCities: ({ commit }, from) => {
    const params = getFilterParams();
    getCities(params)
      .then((response) => {
        commit("setCitiesMutation", response.data.cities);
        commit("setCitiesTotal", response.data.total);
        if (from && parseInt(response.data.total, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadCitiesAll: ({ commit }) => {
    const params = getFilterParams();
    getCitiesForMenus(params)
      .then((response) => {
        // console.log("teste ", response);
        commit("setCitiesAllMutation", response.data.cities);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getCityById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.cities.id);
        commit("setNameMutation", response.data.cities.name);
        commit("setStateMutation", response.data.cities.state);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },
  // ACTIONS - FIELDS TO API
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },
  setState: ({ commit }, payload) => {
    commit("setStateMutation", payload);
  },
  setCity: ({ commit }, payload) => {
    commit("setCityMutation", payload);
  },
  setNeighborhoodsNames: ({ commit }, payload) => {
    commit("setNeighborhoodsNamesMutation", payload.values);
  },
  setNeighborhoodArea: ({ commit }, payload) => {
    commit("setNeighborhoodAreaMutation", payload);
  },
  // ACTIONS - TOGGLES AND MODALS
  openModalAddCity: async ({ commit }) => {
    commit("toggleModalAddCity");
  },
  closeModalAddCity: ({ commit }) => {
    commit("toggleModalAddCity");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openEditModalAddCity: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddCity");
  },
  closeEditModalAddCity: ({ commit }) => {
    commit("toggleModalAddCity");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewCity");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewCity");
    commit("clearFields");
  },
  toggleModalDeleteCity: ({ commit }, payload) => {
    commit("toggleModalDeleteCity");
    if (state.modalToggleDeleteCity && payload) {
      commit("cityForDeletion", payload);
    } else {
      commit("cityForDeletion", null);
    }
  },
  // --> ACTIONS - FILTERLIST
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },
  setFilterState: ({ commit }, payload) => {
    commit("setFilterStateMutation", payload);
  },

  // --> ACTIONS - PAGINATIONS <--
  setCitiesTotalPerPage: ({ commit }, payload) => {
    commit("setCitiesTotalPerPage", payload.totalPerPage);
  },
  setCitiesOffset: ({ commit }, payload) => {
    commit("setCitiesOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  //ACTIONS - CRUD
  save: async ({ commit, dispatch }) => {
    // await fillNeighborhoodAttributes(commit);
    createCity(state.city_fields)
      .then(() => {
        Vue.$toast.success("Município cadastrado com sucesso!");
        dispatch("closeModalAddCity");
        dispatch("loadCities");
        dispatch("loadCitiesAll");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar o município! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  update: ({ commit, dispatch, state }) => {
    updateCity(state.city_fields.id, state.city_fields)
      .then(() => {
        dispatch("loadCities");
        dispatch("loadCitiesAll");
        dispatch("closeEditModalAddCity");
        Vue.$toast.success("Município atualizado com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar o município! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyCity(state.cityId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Município deletado com sucesso!");
        if (state.modalToggleDeleteCity) {
          commit("toggleModalDeleteCity");
        }
        dispatch("loadCities", from);
        dispatch("loadCitiesAll");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.userMessage + "!");
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
};
const getters = {
  getErrorMessage: (state) => (key) => {
    let field = null;
    if (state.errorMessages.fields && state.errorMessages.fields.length > 0) {
      state.errorMessages.fields.forEach((item) => {
        if (item.name === key) {
          field = item.userMessage;
        }
      });
    }
    return field;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
