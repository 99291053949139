<template>
  <div>
    <BaseModal headerColor="#f1f1f1" v-if="open">
      <template v-slot:header>
        <div class="flex flex-row-reverse">
          <AppButtonIcon for-close dispatch="ModalInscription/closeModal" />
        </div>
        <div class="flex flex-wrap mb-3 justify-center">
          <div id="avatar" class="mr-6">
            <img src="../assets/images/profile-vj.png" class="rounded-full w-44 h-44" alt="Foto Profile" />
          </div>
          <div id="options" class="md:w-full lg:w-full xl:w-5/6">
            <div id="name-student">
              <BaseText
                :text="student?.social_name ? `${student?.social_name} - ${student?.name}` : student?.name"
                type-text="custom"
                weight="medium"
                mode="capitalize"
                color="quantum"
                size="xl"
              />
              <BaseText
                :text="`, ${String(student.age)}`"
                type-text="custom"
                weight="medium"
                color="quantum"
                size="xl"
              />
            </div>
            <div id="button-status" class="flex items-center mb-3 col-span-5">
              <div id="label-status" class="mt-4">
                <BaseText
                  text="status cadastral"
                  type-text="custom"
                  weight="semibold"
                  mode="uppercase"
                  color="ashes-dark"
                  size="xs"
                  class="mr-4"
                />
              </div>
              <div id="buttons" class="flex flex-wrap">
                <AppButtonStatus
                  text="Em aberto"
                  type-button="primary"
                  :active="isActive('open')"
                  :disabled="isActive('open') || !season_date_manual_execution_end"
                  class="mr-4 mt-4"
                  status="open"
                  :payload="{ mutation: 'toggleModalInscriptionStatusOpenConfirmation' }"
                  dispatch="ModalInscription/toggleModalInscriptionStatus"
                />
                <AppButtonStatus
                  text="Ativo"
                  icon="done"
                  type-button="success"
                  :active="isActive('accepted')"
                  :disabled="isActive('accepted') || !season_date_manual_execution_end"
                  class="mr-4 mt-4"
                  status="accepted"
                  :payload="{ mutation: 'toggleModalInscriptionStatusAcceptedConfirmation' }"
                  dispatch="ModalInscription/toggleModalInscriptionStatus"
                />
                <AppButtonStatus
                  text="Desligado"
                  icon="close"
                  type-button="danger"
                  :active="isActive('not_accepted')"
                  :disabled="isActive('not_accepted') || !season_date_manual_execution_end"
                  class="mr-4 mt-4"
                  status="not_accepted"
                  :payload="{ mutation: 'toggleModalInscriptionStatusNotAcceptedConfirmation' }"
                  dispatch="ModalInscription/toggleModalInscriptionStatus"
                />
                <AppButtonStatus
                  text="Concludente"
                  icon="close"
                  type-button="primary"
                  :active="isActive('graduate')"
                  :disabled="isActive('graduate') || !season_date_manual_execution_end"
                  class="mr-4 mt-4"
                  status="graduate"
                  :payload="{ mutation: 'toggleModalInscriptionStatusGraduateConfirmation' }"
                  dispatch="ModalInscription/toggleModalInscriptionStatus"
                />
                <AppButtonStatus
                  text="Arquivado"
                  icon="close"
                  type-button="danger"
                  :active="isActive('archived')"
                  :disabled="isActive('archived') || !season_date_manual_execution_end"
                  class="mr-4 mt-4"
                  status="archived"
                  :payload="{ mutation: 'toggleModalInscriptionStatusArchivedConfirmation' }"
                  dispatch="ModalInscription/toggleModalInscriptionStatus"
                />
              </div>
            </div>
            <div class="mb-5 flex justify-between">
              <div class="flex flex-wrap">
                <AppRadioButton
                  :values="[
                    { name: 'contacted', value: true, description: 'sim', checked: inscription.contacted },
                    { name: 'contacted', value: false, description: 'não', checked: !inscription.contacted },
                  ]"
                  label-text="feito contato por telefone?"
                  show-label
                  class="mr-10 relative -inset-y-1.5 mt-4"
                  dispatch="Inscription/setContacted"
                  v-if="!showFormEditInscription && season_date_manual_execution_end"
                />
                <AppTextField
                  show-label
                  label-text="feito contato por telefone?"
                  class="mr-10 relative -inset-y-1.5 mt-5"
                  :text="inscription.contacted ? 'Sim' : 'Não'"
                  type-text="topic-details"
                  v-if="!showFormEditInscription && !season_date_manual_execution_end"
                />
                <div>
                  <BaseInput
                    :active="season_date_manual_execution_end"
                    input-name="attendance-date"
                    input-type="date"
                    show-icon
                    icon-name="today"
                    show-label
                    :value="inscription.date_face_to_face_service"
                    label-text="data da matrícula"
                    class="mr-8 mt-4 w-64 bg-transparent"
                    dispatch="Inscription/setDateFaceToFaceService"
                    v-if="!showFormEditInscription && season_date_manual_execution_end"
                  />
                  <AppTextField
                    show-label
                    label-text="data da matrícula"
                    class="mr-10 relative -inset-y-1.5 mt-5"
                    :text="formattedDate(inscription.date_face_to_face_service)"
                    type-text="topic-details"
                    v-if="!showFormEditInscription && !season_date_manual_execution_end"
                  />
                </div>
                <div class="mr-10 mt-4 flex self-end show">
                  <a href="/docs/ficha_matricula.pdf" target="_blank">
                    <AppButtonColorful
                      text="Imprimir declaração"
                      type-button="primary"
                      show-icon
                      icon-name="description"
                      icon-type="outlined"
                      v-if="!showFormEditInscription"
                    />
                  </a>
                </div>
              </div>
              <div class="flex flex-row-reverse self-end" v-if="season_date_manual_execution_end">
                <!--
                <AppButtonIcon
                  icon-name="print"
                  icon-type="material"
                  icon-size="24"
                  :for-close="false"
                  :dispatch="null"
                  :on-click="null"
                />
                -->
                <AppButtonIcon
                  icon-name="edit"
                  icon-type="material"
                  icon-size="24"
                  :for-close="false"
                  :payload="student"
                  dispatch="ModalInscription/editFormStudent"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div class="container1">
          <div class="container2">
            <FormStudent v-if="showFormEditInscription" />
            <Student v-else />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <form enctype="multipart/form-data">
          <div
            id="filesUpload"
            class="grid grid-cols-5 justify-between p-4 items-end gap-4"
            v-if="!showFormEditInscription"
          >
            <div>
              <DeleteDocumentConfirmation :open="modalDeleteDocumentOpen" />
              <div>
                <AppButtonUploadFile2
                  :disabled="!season_date_manual_execution_end"
                  id="file-rg"
                  :id_file="`${idFileStudentRG}`"
                  name="rg"
                  text="+  RG"
                  dispatch="ModalInscription/setDocumentInscriptionRG"
                  type-document="rg"
                  class="mr-2"
                />
              </div>
              <div class="buttons-insc">
                <AppButtonIcon
                  iconName="delete"
                  :payload="student"
                  dispatch="ModalInscription/deleteStudentDocumentRG"
                  v-if="season_date_manual_execution_end"
                />
                <AppButtonIcon
                  iconName="download"
                  :payload="student"
                  dispatch="ModalInscription/showStudentDocumentRG"
                />
              </div>
            </div>
            <div>
              <AppButtonUploadFile2
                :disabled="!season_date_manual_execution_end"
                id="file-cpf"
                :id_file="`${idFileStudentCPF}`"
                name="cpf"
                text="+  CPF"
                dispatch="ModalInscription/setDocumentInscriptionCPF"
                type-document="cpf"
                class="mr-2"
              />
              <div class="buttons-insc">
                <AppButtonIcon
                  iconName="delete"
                  :payload="student"
                  dispatch="ModalInscription/deleteStudentDocumentCPF"
                  v-if="season_date_manual_execution_end"
                />
                <AppButtonIcon
                  iconName="download"
                  :payload="student"
                  dispatch="ModalInscription/showStudentDocumentCPF"
                />
              </div>
            </div>
            <!--<div>
              <AppButtonUploadFile2
                id="file-cn"
                :id_file="`${idFileStudentCN}`"
                name="cn"
                text="+ Comp. Endereço"
                dispatch="ModalInscription/setDocumentInscriptionCN"
                type-document="cn"
                class="mr-2"
              />
              <div class="buttons-insc">
                <AppButtonIcon
                  iconName="delete"
                  :payload="student"
                  dispatch="ModalInscription/deleteStudentDocumentCN"
                />
                <AppButtonIcon
                  iconName="download"
                  :payload="student"
                  dispatch="ModalInscription/showStudentDocumentCN"
                />
              </div>
            </div> -->
            <div>
              <AppButtonUploadFile2
                :disabled="!season_date_manual_execution_end"
                id="file-ce"
                :id_file="`${idFileStudentCE}`"
                name="ce"
                text="+ Endereço"
                dispatch="ModalInscription/setDocumentInscriptionCE"
                type-document="ce"
                class="mr-2"
              />
              <div class="buttons-insc">
                <AppButtonIcon
                  iconName="delete"
                  :payload="student"
                  dispatch="ModalInscription/deleteStudentDocumentCE"
                  v-if="season_date_manual_execution_end"
                />
                <AppButtonIcon
                  iconName="download"
                  :payload="student"
                  dispatch="ModalInscription/showStudentDocumentCE"
                />
              </div>
            </div>
            <div>
              <AppButtonUploadFile2
                :disabled="!season_date_manual_execution_end"
                id="file-declaration"
                :id_file="`${idFileStudentDeclaracao}`"
                name="declaração"
                text="+  Comprov. Matrícula"
                dispatch="ModalInscription/setDocumentInscriptionDECLARACAO"
                type-document="declaration"
                class="mr-2 w-full"
                color="burnedYellow"
              />
              <div class="buttons-insc">
                <AppButtonIcon
                  iconName="delete"
                  :payload="student"
                  dispatch="ModalInscription/deleteStudentDocumentDECLARACAO"
                  v-if="season_date_manual_execution_end"
                />
                <AppButtonIcon
                  iconName="download"
                  :payload="student"
                  dispatch="ModalInscription/showStudentDocumentDECLARACAO"
                />
              </div>
            </div>
            <div>
              <AppButtonUploadFile2
                :disabled="!season_date_manual_execution_end"
                id="file-school-declaration"
                :id_file="`${idFileStudentSchoolDeclaration}`"
                name="schoolDeclaration"
                text="+  Declaração Escolar"
                dispatch="ModalInscription/setDocumentInscriptionSchoolDeclaration"
                type-document="SchoolDeclaration"
                class="mr-2 w-full"
                color="burnedYellow"
              />
              <div class="buttons-insc">
                <AppButtonIcon
                  iconName="delete"
                  :payload="student"
                  dispatch="ModalInscription/deleteStudentDocumentSchoolDeclaration"
                  v-if="season_date_manual_execution_end"
                />
                <AppButtonIcon
                  iconName="download"
                  :payload="student"
                  dispatch="ModalInscription/showStudentDocumentSchoolDeclaration"
                />
              </div>
            </div>
            <div>
              <AppButtonUploadFile2
                :disabled="!season_date_manual_execution_end"
                id="file-bankaccount"
                :id_file="`${idFileStudentBankAccount}`"
                name="bankaccount"
                text="+  Conta Bancária"
                dispatch="ModalInscription/setDocumentInscriptionBankAccount"
                type-document="BankAccount"
                class="mr-2"
              />
              <div class="buttons-insc">
                <AppButtonIcon
                  iconName="delete"
                  :payload="student"
                  dispatch="ModalInscription/deleteStudentDocumentBankAccount"
                  v-if="season_date_manual_execution_end"
                />
                <AppButtonIcon
                  iconName="download"
                  :payload="student"
                  dispatch="ModalInscription/showStudentDocumentBankAccount"
                />
              </div>
            </div>
            <div>
              <AppButtonUploadFile2
                :disabled="!season_date_manual_execution_end"
                id="file-resume"
                :id_file="`${idFileStudentResume}`"
                name="bankaccount"
                text="+  Currículo"
                dispatch="ModalInscription/setDocumentInscriptionResume"
                type-document="Resume"
                class="mr-2"
              />
              <div class="buttons-insc">
                <AppButtonIcon
                  iconName="delete"
                  :payload="student"
                  dispatch="ModalInscription/deleteStudentDocumentResume"
                  v-if="season_date_manual_execution_end"
                />
                <AppButtonIcon
                  iconName="download"
                  :payload="student"
                  dispatch="ModalInscription/showStudentDocumentResume"
                />
              </div>
            </div>
          </div>
        </form>

        <div class="flex justify-between">
          <BaseInput
            :active="season_date_manual_execution_end"
            id="justify"
            input-name="nome"
            input-type="text"
            :value="inscription.justification"
            label-text="aluno(a) aprovado(a) sem documentos anexados? justifique."
            show-label
            input-placeholder="justificativa"
            dispatch="ModalInscription/setJustification"
            class="w-3/6"
            v-if="!showFormEditInscription && season_date_manual_execution_end"
          />
          <AppTextField
            show-label
            label-text="justificativa para aluno(a) aprovado(a) sem documentos anexados"
            class="mr-10 relative -inset-y-1.5 mt-5"
            :text="inscription.justification ? inscription.justification : 'Nada informado'"
            type-text="topic-details"
            v-if="!showFormEditInscription && !season_date_manual_execution_end"
          />
          <div v-else class="w-3/6"></div>
          <div class="flex justify-end items-end" v-if="!showFormEditInscription">
            <AppButtonEmpty
              text="Descartar"
              type-button="danger"
              dispatch="ModalInscription/closeModal"
              class="mr-5 h-12"
            />
            <AppButtonEmpty
              text="Salvar"
              type-button="success"
              dispatch="ModalInscription/saveInscription"
              class="h-12"
            />
          </div>
          <div class="flex justify-end items-end" v-else>
            <AppButtonEmpty
              text="Descartar"
              type-button="danger"
              dispatch="ModalInscription/closeModal"
              class="mr-5 h-12"
            />
            <AppButtonEmpty
              text="Salvar"
              type-button="success"
              dispatch="ModalInscription/saveInscriptionFrm"
              class="h-12"
            />
          </div>
        </div>
      </template>
    </BaseModal>
    <UnderAnalysisConfirmation :open="modalUnderAnalysisConfirmationOpen" />
    <AcceptedConfirmation :open="modalAcceptedConfirmationOpen" />
    <NotAcceptedConfirmation :open="modalNotAcceptedConfirmationOpen" />
    <OpenConfirmation :open="modalOpenConfirmationOpen" />
    <GraduateConfirmation :open="modalGraduateConfirmationOpen" />
    <ArchivedConfirmation :open="modalArchivedConfirmationOpen" />
    <Alert :open="modalInscriptionAlertStatusOpen" />
  </div>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonStatus from "../components/AppButtonStatus.vue";
import AppRadioButton from "../components/AppRadioButton.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import AppButtonUploadFile2 from "../components/AppButtonUploadFile2.vue";
import AppTextField from "../components/AppTextField.vue";
import BaseText from "../components/BaseText.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import UnderAnalysisConfirmation from "./modal_inscription_status/UnderAnalysisConfirmation.vue";
import AcceptedConfirmation from "./modal_inscription_status/AcceptedConfirmation.vue";
import NotAcceptedConfirmation from "./modal_inscription_status/NotAcceptedConfirmation.vue";
import OpenConfirmation from "./modal_inscription_status/OpenConfirmation.vue";
import GraduateConfirmation from "@/views/modal_inscription_status/GraduateConfirmation.vue";
import ArchivedConfirmation from "@/views/modal_inscription_status/ArchivedConfirmation.vue";
import Alert from "./modal_inscription_status/Alert.vue";
import Student from "../views/Student.vue";
import { mapState } from "vuex";
import FormStudent from "./FormStudent.vue";
import DeleteDocumentConfirmation from "./alerts/DeleteDocumentConfirmation.vue";

export default {
  components: {
    BaseModal,
    BaseText,
    AppButtonStatus,
    AppButtonIcon,
    AppRadioButton,
    AppButtonColorful,
    AppButtonEmpty,
    AppButtonUploadFile2,
    AppTextField,
    BaseInput,
    UnderAnalysisConfirmation,
    AcceptedConfirmation,
    NotAcceptedConfirmation,
    OpenConfirmation,
    GraduateConfirmation,
    ArchivedConfirmation,
    Alert,
    Student,
    FormStudent,
    DeleteDocumentConfirmation,
  },
  props: {
    open: {
      type: Boolean,
    },
  },
  data() {
    return {
      season_date_manual_execution_end: true,
    };
  },
  created() {
    this.getSeasonDateManualExecutionEnd();
  },
  computed: {
    ...mapState({
      modalInscriptionStatusOpen: (state) => state.ModalInscription.modalInscriptionStatusConfirmationOpen,
      modalUnderAnalysisConfirmationOpen: (state) =>
        state.ModalInscription.modalInscriptionStatusUnderAnalysisConfirmation,
      modalAcceptedConfirmationOpen: (state) =>
        state.ModalInscription.modalInscriptionStatusAcceptedConfirmation,
      modalOpenConfirmationOpen: (state) => state.ModalInscription.modalInscriptionStatusOpenConfirmation,
      modalGraduateConfirmationOpen: (state) =>
        state.ModalInscription.modalInscriptionStatusGraduateConfirmation,
      modalArchivedConfirmationOpen: (state) =>
        state.ModalInscription.modalInscriptionStatusArchivedConfirmation,
      modalNotAcceptedConfirmationOpen: (state) =>
        state.ModalInscription.modalInscriptionStatusNotAcceptedConfirmation,
      modalInscriptionAlertStatusOpen: (state) => state.ModalInscription.modalInscriptionAlertStatus,
      inscription: (state) => state.Inscription,
      student: (state) => state.Student,
      showFormEditInscription: (state) => state.ModalInscription.showFormEditInscription,
      modalDeleteDocumentOpen: (state) => state.ModalInscription.modalDeleteDocumentConfirmationOpen,
      idFileStudentRG: (state) => state.ModalInscription.idFileStudentRG,
      idFileStudentCPF: (state) => state.ModalInscription.idFileStudentCPF,
      //idFileStudentCN: (state) => state.ModalInscription.idFileStudentCN,
      idFileStudentCE: (state) => state.ModalInscription.idFileStudentCE,
      idFileStudentDeclaracao: (state) => state.ModalInscription.idFileStudentDeclaracao,
      idFileStudentBankAccount: (state) => state.ModalInscription.idFileStudentBankAccount,
      idFileStudentResume: (state) => state.ModalInscription.idFileStudentResume,
      idFileStudentSchoolDeclaration: (state) => state.ModalInscription.idFileStudentSchoolDeclaration,
    }),
  },

  methods: {
    isActive(status) {
      return status === this.inscription.status;
    },
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    getSeasonDateManualExecutionEnd() {
      this.season_date_manual_execution_end =
        localStorage.getItem("season_date_manual_execution_end") === "null";
    },
  },
};
</script>

<style scoped>
.container1 {
  height: 300px;
  width: 100%;
  overflow: hidden;
}

.container2 {
  width: 105%;
  height: 100%;
  overflow: auto;
  padding-right: 4%;
  padding-left: 4px;
}
</style>
<style>
.buttons-insc {
  margin-top: -10%;
}
.show {
  display: block;
}
.hide {
  display: none;
}
</style>
