<template>
  <BaseModal v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="season.id"
            text="Atualizar edição"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Adicionar edição"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Seasons/closeModal" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="grid grid-rols-2 pb-2 col-span-2">
          <div>
            <BaseInput
              id="name"
              input-name="name"
              input-placeholder="Edição XXX - 2000..."
              input-type="text"
              show-label
              label-text="nome"
              :value="season.name"
              required
              dispatch="Seasons/setSeasonName"
              :error-message="getErrorMessage('name', season.name)"
            />
          </div>
        </div>
        <div class="grid grid-rols-2 pb-2 col-span-2">
          <div>
            <Multiselect
              :close-on-select="false"
              show-label
              clear-on-select
              select-placeholder="Município"
              :multiple-option="true"
              :value="season.cities_attributes"
              :options="cities"
              label-text="Município"
              :dispatch="setSeasonCities"
              required
              load-dispatch="Seasons/loadCities"
            />
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 mt-6">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Período de Inscrições"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="grid grid-rols-2">
          <div>
            <BaseInput
              active
              input-name="date_inscription_start"
              :value="formatDate(season.date_inscription_start)"
              input-type="date"
              show-icon
              icon-name="today"
              show-label
              label-text="início"
              required
              dispatch="Seasons/setDateInscriptionStart"
              :error-message="getErrorMessage('date_inscription_start', season.date_inscription_start)"
            />
          </div>
        </div>
        <div class="ml-4 grid grid-rols-2">
          <div>
            <BaseInput
              active
              input-name="date_inscription_end"
              :value="formatDate(season.date_inscription_end)"
              input-type="date"
              show-icon
              icon-name="today"
              show-label
              label-text="Término"
              required
              dispatch="Seasons/setDateInscriptionEnd"
              :error-message="getErrorMessage('date_inscription_end', season.date_inscription_end)"
            />
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 mt-6">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Período de Execuções"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="grid grid-rols-2">
          <div>
            <BaseInput
              active
              input-name="date_execution_start"
              :value="formatDate(season.date_execution_start)"
              input-type="date"
              show-icon
              icon-name="today"
              show-label
              label-text="Início"
              required
              dispatch="Seasons/setDateExecutionStart"
              :error-message="getErrorMessage('date_execution_start', season.date_execution_start)"
            />
          </div>
        </div>
        <div class="ml-4 grid grid-rols-2">
          <div>
            <BaseInput
              active
              input-name="date_execution_end"
              :value="formatDate(season.date_execution_end)"
              input-type="date"
              icon="today"
              show-icon
              icon-name="today"
              show-label
              label-text="Término"
              required
              dispatch="Seasons/setDateExecutionEnd"
              :error-message="getErrorMessage('date_execution_end', season.date_execution_end)"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Seasons/closeModal" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="season.id"
            text="salvar"
            typeButton="success"
            dispatch="Seasons/updateSeason"
          />
          <AppButtonEmpty v-else text="criar" typeButton="success" dispatch="Seasons/saveSeason" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    BaseModal,
    BaseInput,
    Multiselect,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.$store.dispatch("Areas/loadAreas");
  },

  computed: {
    ...mapGetters({
      getErrorMessage: "Seasons/getErrorMessage",
    }),
    ...mapState({
      season: (state) => state.Seasons.season,
      areas: (state) => state.Areas.areas,
      states: (state) => state.Seasons.states,
      cities: (state) => state.City.citiesAll,
      state: (state) => state.Seasons.state,
      city: (state) => state.Seasons.city,
    }),
  },
  methods: {
    ...mapActions({
      setArea: "Seasons/setSeasonAreas",
      setState: "Seasons/setState",
      setSeasonCities: "Seasons/setSeasonCities",
    }),
    formatDate(date) {
      return date?.split("/").reverse().join("-");
    },
    getLabelCityFilter() {
      return this.state ? "Município" : "Selecione o estado para listar os municípios";
    },
  },
};
</script>
