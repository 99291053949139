<template>
  <BaseModal v-if="open" class="p-6 bg-gray-50 rounded-lg shadow-md">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="text-ashes-hover font-medium text-3xl w-max mb-7">Transferência entre Turmas</div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Season/closeModalTransferStudentOpen" forClose />
        </div>
      </div>
      <div class="w-full mb-6">
        <Multiselect
          id="select-phase"
          show-label
          close-on-select
          label-text="Selecione a fase para que as turmas sejam carregadas"
          required
          select-placeholder="Selecione as opções"
          :clear-on-select="false"
          :multiple-option="false"
          :value="phase_to_transfer_remove_between_teams"
          :options="phases"
          :dispatch="setPhaseToTransferRemoveBetweenTeams"
        />
      </div>
    </template>
    <template v-slot:body>
      <div v-if="phase_to_transfer_remove_between_teams?.id">
        <div v-if="isLoadedTeam" class="mb-5">
          <Label text="Selecione a Turma de Origem:" type="text-lg" weight="bold" class="text-ashes-dark" />
          <TransferStudentCardsTeamSource :data="inscriptionStudentTeamsAttributes" />
        </div>
        <div v-if="isLoadedTeam" class="mb-5">
          <Label text="Selecione a Turma de Destino:" type="text-lg" weight="bold" class="text-ashes-dark" />
          <TransferStudentCardsTeamTarget :data="teams" />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 items-center">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="Descartar"
            typeButton="danger"
            dispatch="Season/closeModalTransferStudentOpen"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            text="Salvar Alterações"
            typeButton="success"
            dispatch="Allocation/transferStudentToTeam"
            :readonly="!targetTeamFiltered || !sourceTeamFiltered"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import TransferStudentCardsTeamTarget from "./list/TransferStudentCardsTeamTarget.vue";
import TransferStudentCardsTeamSource from "./list/TransferStudentCardsTeamSource.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    TransferStudentCardsTeamTarget,
    TransferStudentCardsTeamSource,
    BaseModal,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
    Multiselect,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    textTeamModal: "Turmas sendo carregadas, aguarde...",
  }),
  computed: {
    ...mapState({
      equipment: (state) => state.EquipmentAll.equipmentAll,
      teams: (state) => state.Teams.all,
      inscription: (state) => state.Inscription,
      inscriptions: (state) => state.Season.inscriptions,
      targetTeamFiltered: (state) => state.Season.targetTeamFiltered,
      sourceTeamFiltered: (state) => state.Season.sourceTeamFiltered,
      isLoadedTeam: (state) => state.Season.isLoadedTeam,
      teamsAttributes: (state) => state.Student.teams_attributes,
      inscriptionStudentTeamsAttributes: (state) => state.Season.inscription_student_teams_attributes,
      season: (state) => state.Season.season,
      phases: (state) => state.Season.phases,
      phase_to_transfer_remove_between_teams: (state) => state.Season.phase_to_transfer_remove_between_teams,
      city: (state) => state.City.city,
      team: (state) => state.Teams,
      hasTargetTeams: (state) => state.Season.hasTargetTeams,
      hasSourceTeams: (state) => state.Season.hasSourceTeams,
    }),
  },
  methods: {
    ...mapActions({
      setPhaseToTransferRemoveBetweenTeams: "Season/setPhaseToTransferRemoveBetweenTeams",
    }),
  },
  watch: {
    isLoadedTeam() {
      this.textTeamModal = "Transferir Aluno(a) - Selecione a Turma:";
    },
  },
};
</script>
