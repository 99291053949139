<template>
  <div class="min-h-screen bg-ashes-classic flex flex-col items-center justify-center gap-10">
    <a href="/" class="back-button">
      <span class="material-icons">
        <span class="material-icons"> keyboard_backspace </span>
      </span>
    </a>
    <img src="../assets/images/logotipo-previo.svg" alt="Logotipo PReVIO" class="w-1/3 md:w-1/5" />

    <div class="flex flex-col justify-center items-center">
      <div class="font-medium">Por favor, selecione um projeto:</div>
      <div class="list-projects grid grid-cols-2 md:grid-cols-4 gap-4 items-stretch">
        <router-link to="/admin">
          <button class="card-project">
            <img src="../assets/images/logo.png" alt="Logotipo Projeto" />
          </button>
        </router-link>
        <button type="button" class="card-project opacity-40">
          <img src="../assets/images/logo-intinerarte.png" alt="Logotipo Projeto" />
        </button>
        <button type="button" class="card-project opacity-40">
          <img src="../assets/images/logo-empodera.png" alt="Logotipo Projeto" />
        </button>
        <button type="button" class="card-project opacity-40">
          <img src="../assets/images/logo-projema.png" alt="Logotipo Projeto" />
        </button>
        <button type="button" class="card-project opacity-40">
          <img src="../assets/images/logo-jovens-mediadores.png" alt="Logotipo Projeto" />
        </button>
        <button type="button" class="card-project opacity-40">
          <img src="../assets/images/logo-napaz.png" alt="Logotipo Projeto" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import BaseText from "../components/BaseText.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    // BaseText,
  },
  methods: {
    ...mapActions({
      getPermits: "AccessControl/getPermits",
      getProfPermits: "AccessControl/getProfilePermits",
    }),
  },

  computed: {
    ...mapState({
      perm: (state) => state.AccessControl.perm,
    }),
  },
  created() {
    this.$store.dispatch("AccessControl/getPermits");
  },
};
</script>

<style scoped>
.list-projects {
  margin: 4px 24px 24px;
}

.card-project {
  background: white;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffffff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  transition: all 250ms ease-in-out;
}

.card-project:hover {
  border: 2px solid #40b648;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);
  transition: all 250ms ease-in-out;
}

.card-project.opacity-40 {
  cursor: disabled;
  cursor: not-allowed;
  border: 2px solid #ffffff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  transition: all 250ms ease-in-out;
}

.card-project img {
  max-width: 200px;
  width: 100%;
  height: auto;
}

.back-button {
  color: #1e293b;
  position: absolute;
  top: 5%;
  left: 5%;
}
</style>
