<template>
  <div class="grid auto-grid-auto p-10" v-if="permitList">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-6 my-6">
      <div class="text-ashes-hover font-medium text-2xl col-span-2">
        <Label
          text="Áreas"
          :icon="null"
          :iconType="null"
          type="text-3xl"
          weight="semibold"
          normal
          mode="normal-case"
          :class="title"
        />
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          text="Adicionar área"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="Area/createModal"
          v-if="permit"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-6 mb-2">
      <FilterComponent>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="name"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="nameFiltered"
            label-text="Pesquisar por Nome da Área"
            input-placeholder="Pesquisar por Nome da Área"
            dispatch="Areas/setFilterName"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Filtrar por Município"
            :dispatch="setFilterCity"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-6 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-areas"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Areas/loadAreas"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="areas.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-areas"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-areas">
      <div class="mx-6"><AreaCards :data="areas" /></div>
      <div id="areas-pagination" class="mx-12 my-6 pb-3" v-if="areasTotal > 0">
        <BasePagination :total="areasTotal" :total-per-page="totalPerPage" dispatch="Areas/setAreasOffset" />
      </div>
      <ModalArea :open="openModal" />
      <DeleteAreaConfirmation :open="openModalDelete" />
    </div>
    <div v-if="loading_save_area || loading_update_area">
      <PreLoading />
    </div>
  </div>

  <ProhibitedAccessPage
    classText="grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2"
    v-else
  />
</template>

<script>
import AreaCards from "./list/AreaCards.vue";
import DeleteAreaConfirmation from "./alerts/DeleteAreaConfirmation.vue";
import ModalArea from "./ModalArea.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import ProhibitedAccessPage from "../components/ProhibitedAccessPage.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import { mapState, mapActions } from "vuex";
import Label from "@/components/Label.vue";
import BasePagination from "@/components/BasePagination.vue";
import PreLoading from "@/components/PreLoading.vue";

export default {
  components: {
    PreLoading,
    BasePagination,
    Multiselect,
    FilterComponent,
    Label,
    AreaCards,
    AppButtonColorful,
    BaseInput,
    ModalArea,
    DeleteAreaConfirmation,
    ProhibitedAccessPage,
  },
  data() {
    return {
      permitList: false,
      permit: false,
    };
  },
  created() {
    this.$store.dispatch("Areas/loadAreas");
    this.$store.dispatch("UtilFunctions/loadCities");
    this.permitChangeArea();
    this.permitListArea();
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setFilterCity: "Areas/setFilterCity",
      setFilterState: "Areas/setFilterState",
    }),
    permitChangeArea() {
      this.canAcces("area_create").then((resp) => {
        this.permit = resp;
      });
    },
    permitListArea() {
      this.canAcces("area_index").then((resp) => {
        this.permitList = resp;
      });
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    getLabelCityFilter() {
      return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
    },
    clearFields() {
      this.$store.dispatch("Areas/clearQuery");
    },
  },
  computed: {
    ...mapState({
      areas: (state) => state.Areas.areas,
      areasTotal: (state) => state.Areas.areasTotal,
      openModal: (state) => state.Area.toggleModal,
      openModalDelete: (state) => state.Areas.modalDeleteAreaConfirmationOpen,
      profiles: (state) => state.AccessControl.profiles,
      nameFiltered: (state) => state.Areas.params.name,
      city: (state) => state.Areas.params.city,
      state: (state) => state.Areas.params.state,
      cities: (state) => state.City.citiesAll,
      states: (state) => state.UtilFunctions.states,
      offset: (state) => state.Areas.areasOffset,
      totalPerPage: (state) => state.Areas.areasTotalPerPage,
      loading_save_area: (state) => state.Area.loading.save_area,
      loading_update_area: (state) => state.Area.loading.update_area,
    }),
    title() {
      return {
        "text-ashes-hover": true,
      };
    },
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Areas/loadAreas");
      this.backToTop();
    },
  },
};
</script>
