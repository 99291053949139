<template>
  <BaseCard :class="liClass">
    <div class="grid grid-cols-6 items-center">
      <div class="flex col-span-3 items-center w-100">
        <div :class="iconClass">
          <img src="../assets/images/profile-vj.png" />
        </div>
        <div class="flex flex-col">
          <div class="flex flex-row" v-if="student.status === 'inactive'">
            <span class="badge"> Inativo </span>
          </div>
          <BaseText
            :text="getNameStudent()"
            typeText="custom"
            mode="underline"
            weight="bold"
            size="2xl"
            class="mr-1"
          />
        </div>
      </div>
      <div class="items-center w-60 ml-0">
        <BaseText :text="student.cellphone_number" typeText="custom" size="sm" color="ashes-dark" />
      </div>
      <div class="flex items-center ml-5 col-span-2">
        <div class="justify-items-end ml-0">
          <BaseText
            v-bind:text="
              typeof student.presence_percent !== 'undefined' ? student.presence_percent + '%' : '0%'
            "
            typeText="custom"
            size="sm"
            color="ashes-dark"
          />
        </div>
        <div class="flex items-center ml-1 pr-1 col-span-1 tooltip" v-if="student.presence_percent < 25">
          <button ref="btnRef" v-on:mouseenter="toggleTooltip()" v-on:mouseleave="toggleTooltip()">
            <span class="material-icons mt-2 text-yellow-400">
              <span class="material-icons"> warning </span>
            </span>
          </button>
          <div
            ref="tooltipRef"
            v-bind:class="{ hidden: !tooltipShow, block: tooltipShow }"
            class="bg-white border border-black-600 mr-3 block font-normal leading-normal text-xs text-left break-words rounded-lg tooltiptext shadow-xl w-40"
          >
            <div>
              <div class="text-blue-700 font-semibold p-2">
                <span class="material-icons text-yellow-400">
                  <span class="material-icons"> warning </span>
                </span>
                <span class="tolltiptext-position"> Risco de Evasão!</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import BaseText from "./BaseText.vue";
import { createPopper } from "@popperjs/core";

export default {
  components: {
    BaseCard,
    BaseText,
  },

  data() {
    return {
      tooltipShow: false,
    };
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    student: {
      type: Object,
    },
  },

  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        "p-6": true,
      };
    },
    iconClass() {
      let css = [];
      css.push("rounded-lg");
      css.push("w-12");
      css.push("h-12");
      css.push("grid");
      css.push("mr-3");
      css.push("items-center");
      css.push("justify-center");
      css.push("min-w-1");

      return css;
    },
  },

  methods: {
    permitShowStudent() {
      const role = this.$store.state.Session.role.name;
      if (role == "Administrador" || role == "Gerente de Área") {
        return true;
      } else {
        return false;
      }
    },
    permitEditStudent() {
      const role = this.$store.state.Session.role.name;
      if (role == "Administrador" || role == "Gerente de Área") {
        return true;
      } else {
        return false;
      }
    },
    getNameStudent() {
      return this.student.social_name
        ? `${this.student.social_name} (nome social de: ${this.student.name})`
        : this.student.name;
    },
    toggleTooltip: function () {
      if (this.tooltipShow) {
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        createPopper(this.$refs.btnRef, this.$refs.tooltipRef, {
          placement: "top",
        });
      }
    },
  },
};
</script>

<style scoped>
.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  color: #fff !important;
  background-color: red;
  line-height: 1em;
  text-align: center;
  white-space: nowrap;
  border-radius: 50em;
}
.grid-cols-custom {
  grid-template-columns: 45% 15% 10% 15% 10%;
}
.min-w-1 {
  min-width: 3rem;
}
.tooltip {
  position: relative;
}
.tolltiptext-position {
  position: absolute;
  top: 12px;
  left: 40px;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
