<template>
  <div class="m-5">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5">
      <div class="col-span-full" v-if="isLoggedIn() && student.id">
        <BaseText text="Dados de Inscrição" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-5" v-if="isLoggedIn() && student.id">
        <BaseTextArea
          active
          inputName="devolutiva"
          labelText="Devolutiva da não matrícula"
          showLabel
          rows="4"
          :value="feedback"
          dispatch="Inscription/setFeedback"
        />
      </div>
      <div class="col-span-full">
        <BaseText text="dados pessoais" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-full">
        <BaseInput
          active
          input-name="name"
          :value="student.name"
          show-label
          label-text="nome completo"
          required
          :error-message="getErrorMessage('student.name', student.name)"
          dispatch="Student/setName"
        />
      </div>
      <div class="col-span-full">
        <BaseInput
          active
          input-name="socialName"
          :value="student.social_name"
          show-label
          label-text="Nome Social"
          :error-message="getErrorMessage('student.social_name', student.social_name)"
          dispatch="Student/setSocialName"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="rg"
          :value="student.rg"
          input-placeholder="0000000000000"
          show-label
          label-text="rg"
          dispatch="Student/setRg"
        />
      </div>
      <div v-if="student.id">
        <BaseInput
          active
          input-name="dispatching_agency"
          :value="student.dispatching_agency"
          show-label
          label-text="orgão expeditor"
          dispatch="Student/setDispatchingAgency"
        />
      </div>
      <div v-if="student.id">
        <BaseInput
          active
          input-name="expedition"
          input-type="date"
          :value="student.expedition"
          show-label
          label-text="expedição"
          dispatch="Student/setExpedition"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="cpf"
          :value="student.cpf"
          input-placeholder="000.000.000-000"
          mask="###.###.###-##"
          show-label
          label-text="cpf"
          :error-message="getErrorMessage('student.cpf', student.cpf)"
          dispatch="Student/setCpf"
        />
      </div>
      <div v-if="student.id">
        <BaseInput
          active
          input-name="city_of_birth"
          :value="student.city_of_birth"
          show-label
          label-text="cidade onde nasceu"
          dispatch="Student/setCityOfBirth"
        />
      </div>
      <div v-if="student.id">
        <BaseInput
          active
          input-name="birth_certificate"
          input-placeholder="00000000000000"
          :value="student.birth_certificate"
          show-label
          label-text="certidão de nascimento"
          dispatch="Student/setBirthCertificate"
        />
      </div>
      <div v-if="student.id">
        <BaseInput
          active
          input-name="book"
          :value="student.book"
          show-label
          label-text="livro"
          dispatch="Student/setBook"
        />
      </div>
      <div v-if="student.id">
        <BaseInput
          active
          input-name="page"
          :value="student.page"
          show-label
          label-text="folha"
          dispatch="Student/setPage"
        />
      </div>
      <div class="col-span-full sm:col-span-1 md:col-span-2">
        <BaseInput
          active
          input-name="email"
          :value="student.email"
          show-label
          :error-message="getErrorMessage('student.email', student.email)"
          label-text="email"
          dispatch="Student/setEmail"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="cellphone_number"
          :value="student.cellphone_number"
          input-placeholder="(85) 90000.0000"
          show-label
          required
          mask="(##) #####.####"
          :error-message="getErrorMessage('student.cellphone_number', student.cellphone_number)"
          label-text="telefone"
          dispatch="Student/setCellphoneNumber"
        />
      </div>
      <div class="col-span-full sm:col-span-1 md:col-span-2">
        <BaseInput
          active
          input-name="whatszap"
          :value="student.whatszap"
          :mask="['(##) ####.####', '(##) #####.####']"
          input-placeholder="(85) 90000.0000"
          show-label
          label-text="whatsapp de contato"
          dispatch="Student/setWhatszap"
        />
      </div>
      <div class="col-span-full sm:col-span-1 md:col-span-2 flex-col items-end">
        <BaseInput
          active
          input-name="birthdate"
          input-type="date"
          :value="student.birthdate"
          show-label
          label-text="Data de Nascimento"
          required
          :error-message="getErrorMessage('student.birthdate', student.birthdate)"
          dispatch="Student/addBirthdate"
        />
      </div>
      <div class="col-span-full md:col-span-3 flex justify-center items-center">
        <AppRadioButton
          show-label
          label-text="gênero"
          :values="[
            {
              name: 'gender',
              value: 'Feminino',
              description: 'feminino',
              checked: student.gender === 'Feminino',
            },
            {
              name: 'gender',
              value: 'Masculino',
              description: 'masculino',
              checked: student.gender === 'Masculino',
            },
            {
              name: 'gender',
              value: 'Não-Binárie',
              description: 'Não-Binárie',
              checked: student.gender === 'Não-Binárie',
            },
            {
              name: 'gender',
              value: 'Outros',
              description: 'outros',
              checked: student.gender === 'Outros',
            },
            {
              name: 'gender',
              value: 'Prefiro não Falar',
              description: 'Prefiro não Falar',
              checked: student.gender === 'Prefiro não Falar',
            },
          ]"
          :error-message="getErrorMessage('student.gender', student.gender)"
          required
          dispatch="Student/setGender"
        />
      </div>
      <div class="col-span-full sm:col-span-2 md:col-span-2 flex justify-center items-center">
        <AppRadioButton
          show-label
          label-text="Se identifica como LGBTQIAPN+"
          :values="[
            {
              name: 'identifies_as_lgbtqiapn',
              value: 'Sim',
              description: 'Sim',
              checked: student.identifies_as_lgbtqiapn === 'Sim',
            },
            {
              name: 'identifies_as_lgbtqiapn',
              value: 'Não',
              description: 'Não',
              checked: student.identifies_as_lgbtqiapn === 'Não',
            },
            {
              name: 'identifies_as_lgbtqiapn',
              value: 'Prefiro não Falar',
              description: 'Prefiro não Falar',
              checked: student.identifies_as_lgbtqiapn === 'Prefiro não Falar',
            },
          ]"
          :error-message="getErrorMessage('student.identifies_as_lgbtqiapn', student.identifies_as_lgbtqiapn)"
          required
          dispatch="Student/setIdentifiesAsLgbtqiapn"
        />
      </div>
      <div
        class="col-span-full sm:col-span-1 md:col-span-2"
        v-if="student.identifies_as_lgbtqiapn && student.identifies_as_lgbtqiapn === 'Sim'"
      >
        <BaseInput
          active
          input-name="socialName"
          :value="student.social_name"
          show-label
          label-text="Nome Social"
          :error-message="getErrorMessage('student.social_name', student.social_name)"
          dispatch="Student/setSocialName"
        />
      </div>
      <div v-if="student.id">
        <Multiselect
          id="select-civil-state"
          select-placeholder="Selecionar"
          label-text="Estado Cívil"
          close-on-select
          show-label
          :multiple-option="false"
          :options="civilStates"
          :value="student.civil_state"
          class="mr-5"
          :dispatch="setCivilState"
        />
      </div>
      <div v-if="student.id">
        <BaseInput
          active
          input-name="nis"
          :value="student.nis"
          show-label
          label-text="nis"
          dispatch="Student/setNis"
        />
      </div>
      <div class="col-span-full sm:col-span-1 md:col-span-2" v-if="student.id">
        <Multiselect
          id="select-deficiencie"
          select-placeholder="Escolha uma ou mais opções"
          label-text="Possui alguma deficiência?"
          show-label
          :options="deficiencies"
          :value="student.deficiencies_attributes"
          :dispatch="setDeficiencies"
          class="mr-5"
        />
      </div>
      <div
        class="col-span-full sm:col-span-1 md:col-span-3 flex justify-center items-center"
        v-if="student.id"
      >
        <AppRadioButton
          show-label
          label-text="Pertence a povos originários"
          :values="[
            {
              name: 'belongs_to_indigenous_peoples',
              value: 'Não',
              description: 'Não',
              checked: student.belongs_to_indigenous_peoples === 'Não',
            },
            {
              name: 'belongs_to_indigenous_peoples',
              value: 'Indígena',
              description: 'Indígena',
              checked: student.belongs_to_indigenous_peoples === 'Indígena',
            },
            {
              name: 'belongs_to_indigenous_peoples',
              value: 'Quilombola',
              description: 'Quilombola',
              checked: student.belongs_to_indigenous_peoples === 'Quilombola',
            },
            {
              name: 'belongs_to_indigenous_peoples',
              value: 'Cigano',
              description: 'Cigano',
              checked: student.belongs_to_indigenous_peoples === 'Cigano',
            },
            {
              name: 'belongs_to_indigenous_peoples',
              value: 'Outros',
              description: 'Outros',
              checked: student.belongs_to_indigenous_peoples === 'Outros',
            },
          ]"
          dispatch="Student/setBelongsToIndigenousPeoples"
        />
      </div>
      <div class="col-span-full sm:col-span-2 md:col-span-3" v-if="student.id">
        <Multiselect
          id="select-race"
          select-placeholder="Selecionar"
          label-text="Cor / Raça"
          close-on-select
          show-label
          :multiple-option="false"
          :options="races"
          :value="student.race"
          class="mr-5"
          :dispatch="setRace"
        />
      </div>
      <!--      <div class="col-span-full sm:col-span-1 md:col-span-2">-->
      <!--        <Multiselect-->
      <!--          id="select-number-sons"-->
      <!--          select-placeholder="Selecionar"-->
      <!--          label-text="Possui filhos?"-->
      <!--          close-on-select-->
      <!--          :multiple-option="false"-->
      <!--          show-label-->
      <!--          :options="numbersSons"-->
      <!--          :value="student.number_sons"-->
      <!--          class="mr-5"-->
      <!--          :dispatch="setNumberSons"-->
      <!--          required-->
      <!--          :error-message="getErrorMessage('student.number_sons', student.number_sons)"-->
      <!--        />-->
      <!--      </div>-->
      <div>
        <AppRadioButton
          show-label
          label-text="Possui Filhos(a)?"
          :values="[
            {
              name: 'has_sons',
              value: true,
              description: 'sim',
              checked: student.has_sons === true,
            },
            {
              name: 'has_sons',
              value: false,
              description: 'não',
              checked: student.has_sons === false,
            },
          ]"
          :error-message="getErrorMessage('student.has_sons', student.has_sons)"
          dispatch="Student/setHasSons"
          required
        />
      </div>
      <div>
        <AppRadioButton
          show-label
          label-text="Está grávida?"
          :values="[
            {
              name: 'is_pregnant',
              value: true,
              description: 'sim',
              checked: student.is_pregnant === true,
            },
            {
              name: 'is_pregnant',
              value: false,
              description: 'não',
              checked: student.is_pregnant === false,
            },
          ]"
          :error-message="getErrorMessage('student.is_pregnant', student.is_pregnant)"
          dispatch="Student/setIsPregnant"
          required
        />
      </div>
      <div class="col-span-full sm:col-span-1 md:col-span-2" v-if="student.is_pregnant === true">
        <Multiselect
          id="select-month-pregnant"
          select-placeholder="Selecionar"
          label-text="Mês da gestação"
          close-on-select
          show-label
          :multiple-option="false"
          :options="monthPregnant"
          :value="student.month_pregnant"
          :dispatch="setMonthPregnant"
          class="mr-5"
        />
      </div>
      <div class="col-span-full sm:col-span-1 md:col-span-2" v-if="student.id">
        <BaseInput
          active
          input-name="voter_title"
          :value="student.document_attributes.voter_title"
          show-label
          mask="#### #### ####"
          label-text="Título de eleitor (jovens a partir de 16 anos):"
          dispatch="Student/setVoterTitle"
        />
      </div>
      <div v-if="student.id">
        <BaseInput
          active
          input-name="work_permit"
          :value="student.document_attributes.work_permit"
          mask="###.#####.##-#"
          show-label
          label-text="Carteira de trabalho:"
          dispatch="Student/setWorkPermit"
          width="36px"
        />
      </div>
      <div class="col-span-full sm:col-span-1 md:col-span-2" v-if="student.id">
        <BaseInput
          active
          input-name="reserve_card"
          :value="student.document_attributes.reserve_card"
          show-label
          mask="##.####.######-#"
          label-text="carteira de reservista (homens a partir de 18 anos):"
          dispatch="Student/setReserveCard"
        />
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mt-10">
      <div class="col-span-full">
        <BaseText text="Endereço" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div>
        <BaseInput
          active
          input-name="cep"
          :value="student.cep"
          input-placeholder="00000-000"
          show-label
          mask="#####-###"
          label-text="cep"
          :error-message="getErrorMessage('student.cep', student.cep)"
          dispatch="Student/setCep"
        />
      </div>
      <div class="col-span-full sm:col-span-1 md:col-span-2">
        <BaseInput
          active
          input-name="street"
          :value="student.street"
          show-label
          label-text="rua"
          dispatch="Student/setStreet"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="street_number"
          :value="student.street_number"
          show-label
          label-text="número"
          dispatch="Student/setStreetNumber"
        />
      </div>
      <!--      <div>-->
      <!--        <Multiselect-->
      <!--          id="select-state"-->
      <!--          select-placeholder="Selecionar"-->
      <!--          label-text="Estado"-->
      <!--          :value="student.state"-->
      <!--          close-on-select-->
      <!--          show-label-->
      <!--          required-->
      <!--          :error-message="getErrorMessage('student.state', student.state)"-->
      <!--          :multiple-option="false"-->
      <!--          :options="states"-->
      <!--          :dispatch="setState"-->
      <!--          class="mr-5"-->
      <!--        />-->
      <!--      </div>-->
      <div>
        <Multiselect
          id="select-city"
          select-placeholder="Selecionar"
          label-text="Cidade"
          :value="student.city"
          close-on-select
          show-label
          :multiple-option="false"
          :options="cities"
          required
          :error-message="getErrorMessage('student.city', student.city)"
          :dispatch="setCity"
          class="mr-5"
        />
      </div>
      <div>
        <Multiselect
          id="select-neighborhood"
          select-placeholder="Selecionar"
          label-text="Bairro"
          :value="student.neighborhood"
          close-on-select
          show-label
          required
          :error-message="getErrorMessage('student.neighborhood', student.neighborhood)"
          :multiple-option="false"
          :options="neighborhoods"
          :dispatch="setNeighborhood"
          class="mr-5"
        />
      </div>
      <div v-if="student.id">
        <BaseInput
          active
          input-name="community"
          :value="student.community"
          show-label
          label-text="comunidade"
          dispatch="Student/setCommunity"
        />
      </div>
      <div class="col-span-full sm:col-span-1 md:col-span-2" v-if="student.id">
        <BaseInput
          active
          input-name="complement"
          :value="student.complement"
          show-label
          label-text="complemento (opcional)"
          dispatch="Student/setComplement"
        />
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mt-10" v-if="student.id">
      <div class="col-span-full">
        <BaseText text="Dados de Matrícula" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-full sm:col-span-2 md:col-span-3" v-if="student.id">
        <Multiselect
          id="select-qualification-status"
          select-placeholder="Selecionar"
          label-text="Selecionar Status Qualificação"
          close-on-select
          show-label
          :multiple-option="false"
          :options="qualificationStatus"
          :value="student.qualification_status"
          :dispatch="setQualificationStatus"
          class="mr-5"
        />
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-10" v-if="student.id">
      <div class="col-span-full">
        <BaseText text="escolha a edição da qual quer participar" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div>
        <Multiselect
          id="select-season"
          select-placeholder="Selecionar"
          label-text="Edição"
          :value="student.season"
          close-on-select
          show-label
          required
          :error-message="getErrorMessage('season', season_id)"
          :multiple-option="false"
          :options="seasons"
          :dispatch="setSeason"
        />
      </div>
      <div>
        <Multiselect
          id="select-equipment"
          select-placeholder="Selecionar"
          label-text="Equipamento"
          :error-message="getErrorMessage('equipment', equipment)"
          :value="student.equipment"
          close-on-select
          show-label
          :multiple-option="false"
          :options="equipmentAll"
          :dispatch="setEquipment"
        />
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-10" v-if="student.id">
      <div class="col-span-full">
        <BaseText text="Informações de Área" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div>
        <div>
          <AppTextField
            show-label
            label-text="Área setada automaticamente"
            :text="automatic_area?.name"
            type-text="topic-details"
          />
        </div>
      </div>
      <div>
        <Multiselect
          id="select-manual-area"
          select-placeholder="Selecionar"
          label-text="Área Manual"
          required
          :value="student.manual_area"
          close-on-select
          show-label
          :multiple-option="false"
          :options="userAreas"
          :dispatch="setManualArea"
        />
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-5 mt-10">
      <div class="col-span-full">
        <BaseText text="dados escolares" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div>
        <AppRadioButton
          show-label
          label-text="frequenta a escola?"
          :values="[
            {
              name: 'studying',
              value: true,
              description: 'sim',
              checked: student.studying === true,
            },
            {
              name: 'studying',
              value: false,
              description: 'não',
              checked: student.studying === false,
            },
          ]"
          required
          :error-message="getErrorMessage('student.studying', student.studying)"
          dispatch="Student/setStudying"
        />
      </div>
      <div class="col-span-full sm:col-span-1">
        <div v-if="student.studying === true">
          <img class="inline-block" src="../assets/images/icon-alerta.svg" alt="" />
          <span class="font-system" style="font-size: 0.65rem">
            APENAS JOVENS NO SISTEMA SOCIOEDUCATIVO ESTÃO DENTRO DO PRÉ-REQUISITO PARA PARTICIPAR DO PROJETO
            VIRANDO O JOGO. Jovens regularmente matriculados na rede de ensino fora desta exceção não devem
            participar.
          </span>
        </div>
      </div>
      <div class="col-span-full sm:col-span-2 md:col-span-3" v-if="student.id">
        <Multiselect
          id="select-completed-series"
          select-placeholder="Selecionar"
          label-text="qual a sua última série concluída?"
          close-on-select
          show-label
          :multiple-option="false"
          :options="completedSeries"
          :value="student.completed_series"
          :dispatch="setCompletedSeries"
          class="mr-5"
        />
      </div>
      <div class="col-span-full sm:col-span-2 md:col-span-3" v-if="student.id">
        <Multiselect
          id="select-last-school-studied"
          select-placeholder="Selecionar"
          label-text="Última escola em que estudou?"
          close-on-select
          show-label
          :multiple-option="false"
          :options="facilities"
          :value="student.last_school_studied"
          :dispatch="setLastSchoolStudied"
          class="mr-5"
        />
      </div>
      <div class="col-span-full sm:col-span-2 md:col-span-3" v-if="student.id">
        <Multiselect
          id="select-year-of-last-enrollment"
          select-placeholder="Selecionar"
          label-text="Ano da última matrícula?"
          close-on-select
          show-label
          :multiple-option="false"
          :options="years_of_last_enrollment"
          :value="student.year_of_last_enrollment"
          :dispatch="setYearOfLastEnrollment"
          class="mr-5"
        />
      </div>
      <div class="col-span-full sm:col-span-2 md:col-span-3" v-if="student.id">
        <Multiselect
          id="select-educational-status"
          select-placeholder="Selecionar"
          label-text="Selecionar Status Escolar"
          close-on-select
          show-label
          :multiple-option="false"
          :options="educationalStatus"
          :value="student.educational_status"
          :dispatch="setEducationalStatus"
          class="mr-5"
        />
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-5 mt-10">
      <div class="col-span-full">
        <BaseText text="dados complementares" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div>
        <AppRadioButton
          show-label
          label-text="tem ocupação formal?"
          :values="[
            {
              name: 'busy',
              value: true,
              description: 'sim',
              checked: student.busy === true,
            },
            {
              name: 'busy',
              value: false,
              description: 'não',
              checked: student.busy === false,
            },
          ]"
          required
          :error-message="getErrorMessage('student.busy', student.busy)"
          dispatch="Student/setBusy"
        />
      </div>
      <div class="col-span-full sm:col-span-2">
        <div v-if="student.busy === true">
          <img class="inline-block" src="../assets/images/icon-alerta.svg" alt="" />
          <span class="font-system" style="font-size: 0.65rem">
            Jovens que possuem ocupação formal não estão dentro do pré-requisito para participar do projeto
            virando o jogo.
          </span>
        </div>
      </div>
      <div class="col-span-full sm:col-span-2 md:col-span-3" v-if="student.id">
        <Multiselect
          id="select-professional-qualifications"
          select-placeholder="Escolha uma ou mais opções"
          label-text="Escolha uma ou mais qualificações profissionais de seu interesse"
          :value="student.professional_qualifications_attributes"
          :close-on-select="false"
          show-label
          label="description"
          :options="professionalQualifications"
          :dispatch="setProfessionalQualifications"
          class="mr-5"
        />
      </div>
      <div class="col-span-full sm:col-span-2 md:col-span-3" v-if="student.id">
        <AppCheckBox
          show-label
          label-text="ESCOLHA QUAIS OS TURNOS VOCÊ TEM DISPONÍVEIS PARA PARTICIPAR DO PROJETO"
          :values="[
            {
              name: 'morning-shift',
              value: '2',
              description: 'manhã',
              checked: student.morning_shift,
            },
            {
              name: 'afternoon-shift',
              value: '1',
              description: 'tarde',
              checked: student.afternoon_shift,
            },
          ]"
          dispatch="Student/setShift"
        />
      </div>
      <div class="col-span-full sm:col-span-2 md:col-span-3" v-if="student.id">
        <Multiselect
          id="select-institutional-referral"
          select-placeholder="Selecionar"
          label-text="Selecionar Encaminhamento Institucional"
          close-on-select
          show-label
          :multiple-option="false"
          :options="institutionalReferral"
          :value="student.institutional_referral"
          :dispatch="setInstitutionalReferral"
          class="mr-5"
        />
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-6 gap-5 mt-10">
      <div class="col-span-full">
        <BaseText text="área profissional de interesse" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
        <div class="mt-5">
          <BaseText type-text="custom" weight="normal" color="quantum" size="base">
            Marque a área ocupacional na ordem de preferência, sendo a 1ª para a que você mais deseja. <br />
            <strong>Atenção:</strong> Essa escolha trata-se de uma
            <strong><u>SONDAGEM INICIAL</u></strong> para levantamento da demanda/interesse pelas áreas
            ocupacionais. A oferta do curso de qualificação se dará no início da Formação Cidadã.
          </BaseText>
        </div>
      </div>

      <div class="col-span-full md:col-span-6 flex flex-col md:flex-row items-center">
        <span class="mr-2 text-lg font-semibold text-gray-700">1ª</span>
        <Multiselect
          id="select-professional-qualifications-1"
          select-placeholder="1ª Opção"
          label-text="Escolha a 1ª Opção"
          :value="student.first_option_area_of_interest"
          :close-on-select="true"
          show-label
          :multiple-option="false"
          :options="professionalQualificationsWithName"
          :dispatch="setFirstOptionAreaOfInterest"
          class="flex-grow mr-5"
        />
        <span v-if="student.first_option_area_of_interest" class="ml-2 text-sm text-gray-500 italic">
          {{ student.first_option_area_of_interest.description }}
        </span>
      </div>

      <div class="col-span-full md:col-span-6 flex flex-col md:flex-row items-center">
        <span class="mr-2 text-lg font-semibold text-gray-700">2ª</span>
        <Multiselect
          id="select-professional-qualifications-2"
          select-placeholder="2ª Opção"
          label-text="Escolha a 2ª Opção"
          :value="student.second_option_area_of_interest"
          :close-on-select="true"
          show-label
          :multiple-option="false"
          :options="professionalQualificationsWithName"
          :dispatch="setSecondOptionAreaOfInterest"
          class="flex-grow mr-5"
        />
        <span v-if="student.second_option_area_of_interest" class="ml-2 text-sm text-gray-500 italic">
          {{ student.second_option_area_of_interest.description }}
        </span>
      </div>

      <div class="col-span-full md:col-span-6 flex flex-col md:flex-row items-center">
        <span class="mr-2 text-lg font-semibold text-gray-700">3ª</span>
        <Multiselect
          id="select-professional-qualifications-3"
          select-placeholder="3ª Opção"
          label-text="Escolha a 3ª Opção"
          :value="student.third_option_area_of_interest"
          :close-on-select="true"
          show-label
          :multiple-option="false"
          :options="professionalQualificationsWithName"
          :dispatch="setThirdOptionAreaOfInterest"
          class="flex-grow mr-5"
        />
        <span v-if="student.third_option_area_of_interest" class="ml-2 text-sm text-gray-500 italic">
          {{ student.third_option_area_of_interest.description }}
        </span>
      </div>
    </div>

    <div class="grid grid-cols-4 gap-5 mt-10" v-if="student.id">
      <div class="col-span-4">
        <BaseText text="Dados de Conta Bancária" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
      <div class="col-span-full sm:col-span-2 md:col-span-3">
        <Multiselect
          id="select-bank-name"
          select-placeholder="Selecione o seu banco"
          label-text="Nome e código do Banco"
          :value="student.bank_name"
          close-on-select
          show-label
          :multiple-option="false"
          :options="bankNames"
          :error-message="getErrorMessage('student.bank_name', student.bank_name)"
          :dispatch="setBankName"
          class="mr-5"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="agency"
          :value="student.agency"
          input-placeholder="Número da Agência"
          show-label
          label-text="Agência (com dígito, se houver)"
          dispatch="Student/setAgency"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="account"
          :value="student.account"
          input-placeholder="Informe o número da conta sem o dígito"
          show-label
          label-text="Conta"
          dispatch="Student/setAccount"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="account_digit"
          :value="student.account_digit"
          input-placeholder="Informe o dígito da conta"
          show-label
          label-text="Dígito da Conta"
          dispatch="Student/setAccountDigit"
        />
      </div>
      <div>
        <Multiselect
          id="select-account-type"
          select-placeholder="Selecione o tipo da conta"
          label-text="Tipo da Conta"
          :value="student.account_type"
          close-on-select
          show-label
          :multiple-option="false"
          :options="accountTypes"
          :error-message="getErrorMessage('student.account_type', student.account_type)"
          :dispatch="setAccountType"
          class="mr-5"
        />
      </div>
      <div>
        <Multiselect
          id="select-account-format"
          select-placeholder="Selecione o formato da conta"
          label-text="Formato da Conta"
          :value="student.account_format"
          close-on-select
          show-label
          :multiple-option="false"
          :options="accountFormats"
          :error-message="getErrorMessage('student.account_type', student.account_format)"
          :dispatch="setAccountFormat"
          class="mr-5"
        />
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5 mt-10">
      <div>
        <BaseText text="dados do responsável e familiares" type-text="title-forms" />
        <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
      </div>
    </div>
    <div class="mt-5">
      <BaseText type-text="custom" weight="normal" color="quantum" size="base">
        Informações da MÃE são OBRIGATÓRIAS. Se MENOR DE IDADE, informações de RESPONSÁVEL são OBRIGATÓRIAS.
        Se MAIOR DE IDADE, informações de PESSOA DE REFERÊNCIA são OBRIGATÓRIAS.
      </BaseText>
    </div>
    <div class="col-span-full sm:col-span-1 mt-3">
      <div v-if="!student.birthdate">
        <img class="inline-block" src="../assets/images/icon-alerta.svg" alt="" />
        <span class="font-system" style="font-size: 0.65rem">
          O campo 'Data de Nascimento' deve ser preenchido para que os campos de 'Dados do Responsável e
          Familiares' fiquem disponíveis.
        </span>
      </div>
    </div>
    <div v-if="student.birthdate">
      <div
        :key="index"
        v-for="(relative, index) in relatives"
        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mt-10"
      >
        <div v-if="student.id">
          <Multiselect
            id="select-degree-kinship"
            select-placeholder="Selecionar"
            label-text="grau de parentesco"
            close-on-select
            show-label
            :multiple-option="false"
            :options="mountDegreeKinships(index)"
            :value="relative.degree_kinship"
            :error-message="
              getErrorMessage(
                'student.relatives.degree_kinship',
                student.relatives_attributes[index].degree_kinship
              )
            "
            required
            :dispatch="setDegreeKinship"
            class="mr-5"
          />
        </div>
        <!--      <div class="flex items-center">-->
        <!--        <AppRadioButton-->
        <!--          :id="'responsible-' + index"-->
        <!--          :values="[-->
        <!--            {-->
        <!--              name: 'responsible',-->
        <!--              value: index,-->
        <!--              description: 'Marcar como responsável',-->
        <!--            },-->
        <!--          ]"-->
        <!--          dispatch="Student/setResponsible"-->
        <!--          :error-message="-->
        <!--            getErrorMessage('student.relatives.responsible', student.relatives_attributes[index].responsible)-->
        <!--          "-->
        <!--        />-->
        <!--      </div>-->
        <!--      <div class="flex items-center col-span-full sm:col-span-2">-->
        <!--        <AppRadioButton-->
        <!--          :id="'reference_person-' + index"-->
        <!--          :values="[-->
        <!--            {-->
        <!--              name: 'reference_person',-->
        <!--              value: index,-->
        <!--              description: 'Marcar como pessoa de referência',-->
        <!--            },-->
        <!--          ]"-->
        <!--          dispatch="Student/setReferencePerson"-->
        <!--          :error-message="-->
        <!--            getErrorMessage(-->
        <!--              'student.relatives.reference_person',-->
        <!--              student.relatives_attributes[index].reference_person-->
        <!--            )-->
        <!--          "-->
        <!--        />-->
        <!--      </div>-->
        <div
          class="col-span-full"
          v-if="student.id && student.relatives_attributes[index].degree_kinship?.id === 16"
        >
          <BaseTextArea
            active
            inputName="specification-relative"
            labelText="Especificar Grau de Parentesco"
            showLabel
            rows="4"
            :value="student.relatives_attributes[index].specification"
            :error-message="
              getErrorMessage(
                'student.relatives.specification',
                student.relatives_attributes[index].specification
              )
            "
            dispatch="Student/setRelativeSpecification"
            :payload="{ index }"
          />
        </div>
        <div class="col-span-full sm:col-span-1 md:col-span-2">
          <BaseInput
            active
            input-name="name-relative"
            :value="student.relatives_attributes[index].name"
            :error-message="
              getErrorMessage('student.relatives.name', student.relatives_attributes[index].name)
            "
            show-label
            required
            :label-text="
              getTitleNameRelative({
                degree_kinship: relative.degree_kinship,
                responsible: relative.responsible,
                reference: relative.reference_person,
              })
            "
            dispatch="Student/setRelativeName"
            :payload="{ index }"
          />
        </div>
        <div v-if="student.id">
          <BaseInput
            active
            input-name="rg-relative"
            :value="student.relatives_attributes[index].rg"
            show-label
            input-placeholder="0000000000000"
            label-text="rg"
            dispatch="Student/setRelativeRg"
            :payload="{ index }"
          />
        </div>
        <div v-if="student.id">
          <BaseInput
            active
            input-name="cpf-relative"
            :value="student.relatives_attributes[index].cpf"
            :error-message="getErrorMessage('student.relatives.cpf', student.relatives_attributes[index].cpf)"
            show-label
            input-placeholder="000.000.000-000"
            mask="###.###.###-##"
            label-text="cpf"
            dispatch="Student/setRelativeCpf"
            :payload="{ index }"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="cellphone_number-relative"
            :value="student.relatives_attributes[index].cellphone_number"
            show-label
            input-placeholder="(85) 90000.0000"
            mask="(##) #####.####"
            label-text="telefone"
            :error-message="
              getErrorMessage(
                'student.relatives.cellphone_number',
                student.relatives_attributes[index].cellphone_number
              )
            "
            dispatch="Student/setRelativeCellphoneNumber"
            :payload="{ index }"
          />
        </div>
        <div v-if="student.id">
          <BaseInput
            active
            input-name="email-relative"
            :value="student.relatives_attributes[index].email"
            show-label
            input-placeholder="digiteseu@email.com"
            label-text="e-mail"
            :error-message="
              getErrorMessage('student.relatives.email', student.relatives_attributes[index].email)
            "
            dispatch="Student/setRelativeEmail"
            :payload="{ index }"
          />
        </div>
        <div class="flex items-end col-span-full sm:col-span-1 md:col-span-2" v-if="student.id">
          <BaseInput
            class="flex-grow"
            active
            input-name="birth-year-relative"
            :value="student.relatives_attributes[index].birth_date"
            show-label
            input-placeholder="Ano"
            label-text="Data de Nascimento"
            input-type="date"
            dispatch="Student/setRelativeBirthDate"
            :payload="{ index }"
          />
        </div>
        <div class="ml-5" v-if="student.id && !relative.responsible && !relative.reference_person">
          <a
            v-if="index >= 1"
            href="javascript:void(0)"
            class="text-burnedYellow underline"
            @click="deleteRelative"
          >
            <AppMaterialIcon icon-name="delete" icon-size="36" class="align-bottom" />
          </a>
        </div>
        <div class="col-span-full mt-5">
          <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
        </div>
      </div>
    </div>
    <div class="mt-5" v-if="student.id">
      <a href="javascript:void(0)" class="text-burnedYellow underline" @click="addRelative">
        <AppMaterialIcon icon-name="add_circle" icon-size="36" class="align-middle" />
        <BaseText
          text="Adicionar familiar"
          type-text="custom"
          weight="medium"
          size="sm"
          class="align-middle mr-2"
        />
      </a>
    </div>
  </div>
</template>

<script>
import AppCheckBox from "../components/AppCheckBox.vue";
import AppMaterialIcon from "../components/AppMaterialIcon.vue";
import AppRadioButton from "../components/AppRadioButton.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import BaseText from "../components/BaseText.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import BaseTextArea from "@/components/forms/BaseTextArea.vue";
import AppTextField from "@/components/AppTextField.vue";

export default {
  components: {
    AppTextField,
    BaseTextArea,
    AppCheckBox,
    AppMaterialIcon,
    AppRadioButton,
    BaseInput,
    BaseText,
    Multiselect,
  },

  created() {
    this.$store.dispatch("Student/loadYears");
    this.$store.dispatch("Student/loadCivilStates");
    this.$store.dispatch("Student/loadStates");
    if (this.student.state) {
      this.$store.dispatch("Student/loadCities", this.student.state.id);
    }
    if (this.student.city) {
      this.$store.dispatch("Student/loadNeighborhoods", this.student.city.id);
      this.$store.dispatch("Student/loadSeasons", this.student.city.id);
      this.$store.dispatch("Student/loadEquipment", this.student.city.id);
      this.$store.dispatch("Student/loadProfessionalQualifications", this.student.city.id);
      this.$store.dispatch("Student/loadSports", this.student.city.id);
    }
    this.$store.dispatch("Student/loadCompletedSeries");
    this.$store.dispatch("Student/loadShirtSizes");
    this.$store.dispatch("Student/loadAccountTypes");
    this.$store.dispatch("Student/loadAccountFormats");
    this.$store.dispatch("Student/loadBankNames");
    this.$store.dispatch("Student/loadPantsSizes");
    this.$store.dispatch("Student/loadShoeSizes");
    this.$store.dispatch("Student/loadDegreeKinships");
    this.$store.dispatch("Races/loadRaces");
    this.$store.dispatch("Deficiencies/loadDeficiencies");
    this.$store.dispatch("Student/loadEducationalStatus");
    this.$store.dispatch("Student/loadQualificationStatus");
    this.$store.dispatch("Student/loadInstitutionalReferrals");
    this.$store.dispatch("Student/loadMonthPregnants");
    this.$store.dispatch("Student/loadFacilities");
    this.$store.dispatch("Student/loadNumbersSons");
    this.$store.dispatch("Student/loadYearsOfLastEnrollment");
    this.$store.dispatch("Student/loadProfessionalQualificationsWithName");
  },

  data() {
    return {
      anos: [],
      ano: null,
      url: window.location.href,
      date_now: new Date(Date.now()).toLocaleDateString(),
      relatives_final: [],
      relatives_initiated: [
        {
          degree_kinship: { id: 3, name: "Mãe" },
          responsible: false,
          reference_person: false,
          specification: null,
          name: null,
          rg: null,
          cpf: null,
          cellphone_number: null,
          email: null,
          birth_date: null,
        },
        {
          degree_kinship: { id: 16, name: "Outro" },
          responsible: true,
          reference_person: false,
          specification: "Familiar Responsável",
          name: null,
          rg: null,
          cpf: null,
          cellphone_number: null,
          email: null,
          birth_date: null,
        },
        {
          degree_kinship: { id: 16, name: "Outro" },
          responsible: false,
          reference_person: true,
          specification: "Pessoa de Referência",
          name: null,
          rg: null,
          cpf: null,
          cellphone_number: null,
          email: null,
          birth_date: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getCivilState: "Student/getCivilState",
      getState: "Student/getState",
      getCity: "Student/getCity",
      getNeighborhood: "Student/getNeighborhood",
      getSeason: "Student/getSeason",
      getEquipament: "Student/getEquipament",
      getCompletedSerie: "Student/getCompletedSerie",
      getProfessionalQualifications: "Student/getProfessionalQualifications",
      getSports: "Student/getSports",
      getShirtSize: "Student/getShirtSize",
      getAccountType: "Student/getAccountType",
      getBankName: "Student/getBankName",
      getEducationalStatus: "Student/getEducationalStatus",
      getQualificationStatus: "Student/getQualificationStatus",
      getInstitutionalReferral: "Student/getInstitutionalReferral",
      getMonthPregnant: "Student/getMonthPregnant",
      getPantsSize: "Student/getPantsSize",
      getShoeSize: "Student/getShoeSize",
      getDegreeKinship: "Student/getDegreeKinship",
      getErrorMessage: "Student/getErrorMessage",
      getDay: "Student/getDay",
      getMonth: "Student/getMonth",
      getYear: "Student/getYear",
      getNumbersSons: "Student/getNumbersSons",
    }),
    ...mapState({
      races: (state) => state.Races.races,
      deficiencies: (state) => state.Deficiencies.deficiencies,
      days: (state) => state.Student.days,
      months: (state) => state.Student.months,
      years: (state) => state.Student.years,
      civilStates: (state) => state.Student.civilStates,
      seasons: (state) => state.Student.seasons,
      states: (state) => state.Student.states,
      cities: (state) => state.Student.cities,
      neighborhoods: (state) => state.Student.neighborhoods,
      student: (state) => state.Student,
      equipmentAll: (state) => state.Student.equipmentAll,
      equipment: (state) => state.Student.equipment,
      season_id: (state) => state.Student.season_id,
      completedSeries: (state) => state.Student.completedSeries,
      educationalStatus: (state) => state.Student.educationalStatus,
      qualificationStatus: (state) => state.Student.qualificationStatus,
      institutionalReferral: (state) => state.Student.institutionalReferral,
      monthPregnant: (state) => state.Student.monthPregnant,
      facilities: (state) => state.Student.facilities,
      shirtSizes: (state) => state.Student.shirtSizes,
      accountTypes: (state) => state.Student.accountTypes,
      accountFormats: (state) => state.Student.accountFormats,
      bankNames: (state) => state.Student.bankNames,
      pantsSizes: (state) => state.Student.pantsSizes,
      shoeSizes: (state) => state.Student.shoeSizes,
      professionalQualifications: (state) => state.Student.professionalQualifications,
      sports: (state) => state.Student.sports,
      relatives: (state) => state.Student.relatives_attributes,
      degreeKinships: (state) => state.Student.degreeKinships,
      professionalQualificationsWithName: (state) => state.Student.professionalQualificationsWithName,
      day: (state) => state.Student.day,
      month: (state) => state.Student.month,
      year: (state) => state.Student.year,
      season: (state) => state.Season.season,
      numbersSons: (state) => state.Student.numbersSons,
      years_of_last_enrollment: (state) => state.Student.years_of_last_enrollment,
      automatic_area: (state) => state.Student.automatic_area,
      manual_area: (state) => state.Student.manual_area,
      birthdate: (state) => state.Student.birthdate,
      responsible_professional: (state) => state.Inscription.responsible_professional,
      feedback: (state) => state.Inscription.feedback,
      first_option_area_of_interest: (state) => state.Student.first_option_area_of_interest,
      second_option_area_of_interest: (state) => state.Student.second_option_area_of_interest,
      third_option_area_of_interest: (state) => state.Student.third_option_area_of_interest,
    }),
    userAreas() {
      return this.$util_function.user_areas();
    },
  },
  methods: {
    ...mapActions({
      setDay: "Student/setDay",
      setMonth: "Student/setMonth",
      setYear: "Student/setYear",
      setCivilState: "Student/setCivilState",
      setRace: "Student/setRace",
      setDeficiencies: "Student/setDeficiencies",
      setState: "Student/setState",
      setCity: "Student/setCity",
      setNeighborhood: "Student/setNeighborhood",
      setSeason: "Student/setSeason",
      setEquipment: "Student/setEquipment",
      setManualArea: "Student/setManualArea",
      setCompletedSeries: "Student/setCompletedSeries",
      setEducationalStatus: "Student/setEducationalStatus",
      setQualificationStatus: "Student/setQualificationStatus",
      setInstitutionalReferral: "Student/setInstitutionalReferral",
      setMonthPregnant: "Student/setMonthPregnant",
      setLastSchoolStudied: "Student/setLastSchoolStudied",
      setYearOfLastEnrollment: "Student/setYearOfLastEnrollment",
      setProfessionalQualifications: "Student/setProfessionalQualifications",
      setSports: "Student/setSports",
      setShirtSize: "Student/setShirtSize",
      setAccountType: "Student/setAccountType",
      setAccountFormat: "Student/setAccountFormat",
      setBankName: "Student/setBankName",
      setPantsSize: "Student/setPantsSize",
      setShoeSize1: "Student/setShoeSize1",
      setShoeSize2: "Student/setShoeSize2",
      setDegreeKinship: "Student/setDegreeKinship",
      setNumberSons: "Student/setNumberSons",
      setRelativeBirthDate: "Student/setRelativeBirthDate",
      setFirstOptionAreaOfInterest: "Student/setFirstOptionAreaOfInterest",
      setSecondOptionAreaOfInterest: "Student/setSecondOptionAreaOfInterest",
      setThirdOptionAreaOfInterest: "Student/setThirdOptionAreaOfInterest",
      editFormStudent: "ModalInscription/editFormStudent",
    }),
    addRelative() {
      this.$store.dispatch("Student/addRelative");
    },
    deleteRelative() {
      this.$store.dispatch("Student/deleteRelative");
    },

    mountDegreeKinships(index) {
      return this.degreeKinships.map((degreeKinship) => {
        return { index, ...degreeKinship };
      });
    },
    isSon(index) {
      if (this.student.relatives_attributes[index].degree_kinship != null) {
        let degree_kinship = this.student.relatives_attributes[index].degree_kinship.id;
        if (degree_kinship === "5" || degree_kinship === "6") {
          return false;
        }
      }
      return true;
    },
    calculateAge(birthDate) {
      const today = new Date();
      if (birthDate) {
        const birth = new Date(birthDate);
        let age = today.getFullYear() - birth.getFullYear();
        const monthDiff = today.getMonth() - birth.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
          age--;
        }
        return age;
      }
    },
    setRelativesBasedOnAge(birthDate) {
      const age = this.calculateAge(birthDate);
      if (age < 18) {
        this.relatives_final = [this.relatives_initiated[0], this.relatives_initiated[1]];
        this.$store.dispatch("Student/setRelatives", this.relatives_final);
      } else {
        this.relatives_final = [this.relatives_initiated[0], this.relatives_initiated[2]];
        this.$store.dispatch("Student/setRelatives", this.relatives_final);
      }
    },
    onBirthDateChange(birthDate) {
      this.setRelativesBasedOnAge(birthDate);
    },
    getTitleNameRelative(payload) {
      let title_name = "Nome Completo";
      if (payload.degree_kinship && payload.degree_kinship.id === 3) {
        title_name = "Nome Completo da Mãe";
      } else {
        if (payload.responsible) {
          title_name = "Nome Completo do Responsável";
        } else if (payload.reference) {
          title_name = "Nome Completo da Pessoa de Referência";
        }
      }
      return title_name;
    },
    isLoggedIn() {
      return (
        this.$util_function.isLoggedIn() &&
        (this.url.includes("/inscricao_interna") || this.url.includes("/edicao"))
      );
    },
  },
  watch: {
    birthdate: function () {
      if (this.birthdate && !this.student.id) {
        this.onBirthDateChange(this.birthdate);
      }
    },
    first_option_area_of_interest: function () {
      this.$store.dispatch("Student/loadProfessionalQualificationsWithName");
    },
    second_option_area_of_interest: function () {
      this.$store.dispatch("Student/loadProfessionalQualificationsWithName");
    },
    third_option_area_of_interest: function () {
      this.$store.dispatch("Student/loadProfessionalQualificationsWithName");
    },
  },
};
</script>
<style scoped>
.col-span-full {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.flex ::v-deep(.flex) {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .md\:col-span-3 {
    flex-direction: column;
    align-items: stretch;
  }
}
</style>
