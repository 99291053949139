import { isEmpty } from "lodash";
import { getDegreeKinships } from "../../service/RelativeApi";
import { getPartialsSeasons } from "../../service/SeasonApi";
import {
  getProfessionalQualificationByCityId,
  getProfessionalQualificationsWithName,
} from "../../service/ProfessionalQualificationApi";
import { getYears, getYearsForLastEnrollment } from "../../service/InscriptionApi";
import { getSportByCityId } from "../../service/SportApi";
import { getAdress } from "../../service/CepApi";
import {
  getCivilStates,
  getCompletedSeries,
  getShirtSizes,
  getBankNames,
  getAccountTypes,
  getAccountFormats,
  getEducationalStatus,
  getInstitutionalReferrals,
  getPantsSizes,
  getShoeSizes,
  getStudentsByCityId,
  getQualificationStatus,
  getMonthPregnants,
} from "../../service/StudentApi";
import { getFacilities } from "../../service/FacilitiesApi";
import { getStates } from "../../service/StateApi";
import { getCitiesByStateId } from "../../service/CityApi";
import { getNeighborhoodsForMenus } from "../../service/NeighborhoodsApi";
import { getEquipmentByCityId } from "../../service/EquipmentApi";
import Vue from "vue";
import { getAreasForMenus } from "@/service/AreaApi";

const state = {
  seasons: [],
  years: [],
  years_of_last_enrollment: [],
  civilStates: [],
  states: [],
  races: [],
  deficiencies: [],
  cities: [],
  neighborhoods: [],
  equipmentAll: [],
  completedSeries: [],
  shirtSizes: [],
  bankNames: [],
  accountTypes: [],
  accountFormats: [],
  educationalStatus: [],
  qualificationStatus: [],
  institutionalReferral: [],
  monthPregnant: [],
  facilities: [],
  pantsSizes: [],
  shoeSizes: [],
  professionalQualifications: [],
  sports: [],
  teams: [],
  degreeKinships: [],
  students: [],
  filterStudents: [],
  allCodesTeams: [],
  areas: [],
  professionalQualificationsWithName: [],
  errorMessages: [],

  season: null,
  avatar: null,
  equipment: null,
  name: null,
  age: null,
  social_name: null,
  rg: null,
  dispatching_agency: null,
  expedition: null,
  cpf: null,
  city_of_birth: null,
  book: null,
  page: null,
  email: null,
  cellphone_number: null,
  whatszap: null,
  birthdate: null,
  birth_certificate: null,
  gender: null,
  automatic_area: null,
  manual_area: null,
  identifies_as_lgbtqiapn: null,
  belongs_to_indigenous_peoples: null,
  civil_state: null,
  cep: null,
  street: null,
  street_number: null,
  community: null,
  complement: null,
  state: { id: 1, name: "Ceará" },
  studying: null,
  is_pregnant: null,
  busy: null,
  completed_series: null,
  city: null,
  neighborhood: null,
  race: null,
  agency: null,
  account: null,
  account_digit: null,
  first_option_area_of_interest: null,
  second_option_area_of_interest: null,
  third_option_area_of_interest: null,
  cumulative_frequency: 0,
  professional_qualifications_attributes: [],
  sports_attributes: [],
  teams_attributes: [],
  document_attributes: {
    id: null,
    work_permit: "",
    voter_title: "",
    reserve_card: "",
  },
  deficiencies_attributes: [],
  morning_shift: false,
  afternoon_shift: false,
  night_shift: false,
  height: null,
  weight: "",
  shirt_size: null,
  bank_name: null,
  account_type: null,
  account_format: null,
  educational_status: null,
  qualification_status: null,
  institutional_referral: null,
  month_pregnant: null,
  last_school_studied: null,
  year_of_last_enrollment: null,
  pants_size: null,
  shoe_size1: null,
  shoe_size2: null,
  nis: null,
  relatives_attributes: [
    {
      degree_kinship: null,
      responsible: null,
      reference_person: null,
      name: null,
      rg: null,
      cpf: null,
      cellphone_number: null,
      email: null,
      birth_date: null,
      specification: null,
    },
  ],
  student_area_exceptions_attributes: {
    id: null,
    area_id: null,
    manual_override: null,
  },
  numbersSons: [],
  number_sons: null,
  has_sons: null,
  season_active: null,
  last_team_informations: null,
  productive_insertion: null,
};

const mutations = {
  loadSeasons: (state, seasons) => {
    seasons ? (state.seasons = seasons) : (state.seasons = state.season_active);
  },
  loadYears: (state, years) => {
    state.years = years;
  },
  loadCivilStates: (state, civilStates) => {
    state.civilStates = civilStates;
  },
  loadCompletedSeries: (state, completedSeries) => {
    state.completedSeries = completedSeries;
  },
  loadShirtSizes: (state, shirtSizes) => {
    state.shirtSizes = shirtSizes;
  },
  loadBankNames: (state, bankNames) => {
    state.bankNames = bankNames;
  },
  loadAccountTypes: (state, accountTypes) => {
    state.accountTypes = accountTypes;
  },
  loadAccountFormats: (state, accountFormats) => {
    state.accountFormats = accountFormats;
  },
  loadEducationalStatus: (state, educationalStatus) => {
    state.educationalStatus = educationalStatus;
  },
  loadQualificationStatus: (state, qualificationStatus) => {
    state.qualificationStatus = qualificationStatus;
  },
  loadInstitutionalReferrals: (state, institutionalReferral) => {
    state.institutionalReferral = institutionalReferral;
  },
  loadMonthPregnants: (state, monthPregnant) => {
    state.monthPregnant = monthPregnant;
  },
  loadFacilities: (state, facilities) => {
    state.facilities = facilities;
  },
  loadYearsOfLastEnrollment: (state, years_of_last_enrollment) => {
    state.years_of_last_enrollment = years_of_last_enrollment;
  },
  loadPantsSizes: (state, pantsSizes) => {
    state.pantsSizes = pantsSizes;
  },
  loadShoeSizes: (state, shoeSizes) => {
    state.shoeSizes = shoeSizes;
  },
  loadStates: (state, states) => {
    state.states = states;
  },
  loadRaces: (state, races) => {
    state.races = races;
  },
  loadDeficiencies: (state, deficiencies) => {
    state.deficiencies = deficiencies;
  },
  loadCities: (state, cities) => {
    state.cities = cities;
  },
  loadNeighborhoods: (state, neighborhoods) => {
    state.neighborhoods = neighborhoods;
  },
  loadEquipment: (state, equipmentAll) => {
    state.equipmentAll = equipmentAll;
  },
  loadProfessionalQualifications: (state, professionalQualifications) => {
    state.professionalQualifications = professionalQualifications;
  },
  loadProfessionalQualificationsWithName: (state, professionalQualificationsWithName) => {
    state.professionalQualificationsWithName = professionalQualificationsWithName;
  },
  loadSports: (state, sports) => {
    state.sports = sports;
  },
  loadTeams: (state, teams) => {
    state.teams = teams;
  },
  loadAreas: (state, areas) => {
    state.areas = areas;
  },
  loadDegreeKinships: (state, degreeKinships) => {
    state.degreeKinships = degreeKinships;
  },
  loadNumbersSons: (state, numbersSons) => {
    state.numbersSons = numbersSons;
  },
  setStudent: (state, student) => {
    state.student = student;
  },
  setSeason: (state, season) => {
    state.season = season;
  },
  setState: (state, stateCountry) => {
    state.state = stateCountry;
  },
  setId: (state, id) => {
    state.id = id;
  },
  setAvatar: (state, avatar) => {
    state.avatar = avatar;
  },
  setName: (state, name) => {
    state.name = name;
  },
  setAge: (state, age) => {
    state.age = age;
  },
  setSocialName: (state, social_name) => {
    state.social_name = social_name;
  },
  setCumulativeFrequency: (state, cumulative_frequency) => {
    state.cumulative_frequency += cumulative_frequency;
  },
  setRg: (state, rg) => {
    state.rg = rg;
  },
  setDispatchingAgency: (state, dispatchingAgency) => {
    state.dispatching_agency = dispatchingAgency;
  },
  setLastTeamInformations: (state, last_team_informations) => {
    state.last_team_informations = last_team_informations;
  },
  setProductiveInsertion: (state, productive_insertion) => {
    state.productive_insertion = productive_insertion;
  },
  setAllCodesTeams: (state, allCodeTeams) => {
    state.allCodesTeams = allCodeTeams;
  },
  setExpedition: (state, expedition) => {
    state.expedition = expedition;
  },
  setCpf: (state, cpf) => {
    state.cpf = cpf;
  },
  setCityOfBirth: (state, cityOfBirth) => {
    state.city_of_birth = cityOfBirth;
  },
  setBook: (state, book) => {
    state.book = book;
  },
  setPage: (state, page) => {
    state.page = page;
  },
  setNis: (state, nis) => {
    state.nis = nis;
  },
  setEmail: (state, email) => {
    state.email = email;
  },
  setCellphoneNumber: (state, cellphoneNumber) => {
    state.cellphone_number = cellphoneNumber;
  },
  setWhatszap: (state, whatszap) => {
    state.whatszap = whatszap;
  },
  setDay: (state, day) => {
    state.day = day;
  },
  setMonth: (state, month) => {
    state.month = month;
  },
  setYear: (state, year) => {
    state.year = year;
  },
  setBirthdate: (state, birthdate) => {
    state.birthdate = birthdate;
  },
  setBirthCertificate: (state, birthCertificate) => {
    state.birth_certificate = birthCertificate;
  },
  setGender: (state, gender) => {
    state.gender = gender;
  },
  setIdentifiesAsLgbtqiapn: (state, identifies_as_lgbtqiapn) => {
    state.identifies_as_lgbtqiapn = identifies_as_lgbtqiapn;
  },
  setBelongsToIndigenousPeoples: (state, belongs_to_indigenous_peoples) => {
    state.belongs_to_indigenous_peoples = belongs_to_indigenous_peoples;
  },
  setCivilState: (state, civilState) => {
    state.civil_state = civilState;
  },
  setCep: (state, cep) => {
    state.cep = cep;
  },
  setStreet: (state, street) => {
    state.street = street;
  },
  setStreetNumber: (state, streetNumber) => {
    state.street_number = streetNumber;
  },
  setCommunity: (state, community) => {
    state.community = community;
  },
  setComplement: (state, complement) => {
    state.complement = complement;
  },
  setStudying: (state, studying) => {
    state.studying = studying;
  },
  setIsPregnant: (state, is_pregnant) => {
    state.is_pregnant = is_pregnant;
  },
  setBusy: (state, busy) => {
    state.busy = busy;
  },
  setCompletedSeries: (state, completedSeries) => {
    state.completed_series = completedSeries;
  },
  setProfessionalQualifications: (state, professionalQualifications) => {
    state.professional_qualifications_attributes = professionalQualifications;
  },
  setDeficiencies: (state, deficiency) => {
    state.deficiencies_attributes = deficiency;
  },
  addDeficiencies: (state, deficiency) => {
    state.deficiencies_attributes.push(deficiency);
  },
  setSports: (state, sports) => {
    state.sports_attributes = sports;
  },
  setTeams: (state, teams) => {
    state.teams_attributes = teams;
  },
  setNightShift: (state, nightShift) => {
    state.night_shift = nightShift;
  },
  setAfternoonShift: (state, afternoonShift) => {
    state.afternoon_shift = afternoonShift;
  },
  setMorningShift: (state, morningShift) => {
    state.morning_shift = morningShift;
  },
  setHeight: (state, height) => {
    state.height = height;
  },
  setWeight: (state, weight) => {
    state.weight = weight;
  },
  setShirtSize: (state, shirtSize) => {
    state.shirt_size = shirtSize;
  },
  setBankName: (state, bankName) => {
    state.bank_name = bankName;
  },
  setEducationalStatus: (state, educationalStatus) => {
    state.educational_status = educationalStatus;
  },
  setQualificationStatus: (state, qualificationStatus) => {
    state.qualification_status = qualificationStatus;
  },
  setFirstOptionAreaOfInterest: (state, first_option_area_of_interest) => {
    state.first_option_area_of_interest = first_option_area_of_interest;
  },
  setSecondOptionAreaOfInterest: (state, second_option_area_of_interest) => {
    state.second_option_area_of_interest = second_option_area_of_interest;
  },
  setThirdOptionAreaOfInterest: (state, third_option_area_of_interest) => {
    state.third_option_area_of_interest = third_option_area_of_interest;
  },
  setInstitutionalReferral: (state, institutionalReferral) => {
    state.institutional_referral = institutionalReferral;
  },
  setMonthPregnant: (state, monthPregnant) => {
    state.month_pregnant = monthPregnant;
  },
  setLastSchoolStudied: (state, last_school_studied) => {
    state.last_school_studied = last_school_studied;
  },
  setAccountType: (state, accountType) => {
    state.account_type = accountType;
  },
  setAccountFormat: (state, accountFormat) => {
    state.account_format = accountFormat;
  },
  setPantsSize: (state, pantsSize) => {
    state.pants_size = pantsSize;
  },
  setShoeSize1: (state, shoeSize1) => {
    state.shoe_size1 = shoeSize1;
  },
  setShoeSize2: (state, shoeSize2) => {
    state.shoe_size2 = shoeSize2;
  },
  setRelatives: (state, relatives) => {
    state.relatives_attributes = relatives;
  },
  setStudentAreaException: (state, student_area_exceptions_attributes) => {
    state.student_area_exceptions_attributes = student_area_exceptions_attributes;
  },
  setManualArea: (state, area) => {
    state.manual_area = area;

    if (!state.student_area_exceptions_attributes) {
      state.student_area_exceptions_attributes = {
        id: null,
        area_id: null,
      };
    }

    state.student_area_exceptions_attributes.area_id = area ? area.id : null;
  },
  setCity: (state, city) => {
    state.city = city;
  },
  setNeighborhood: (state, neighborhood) => {
    state.neighborhood = neighborhood;
    state.neighborhood_id = neighborhood?.id;
  },
  setRace: (state, race) => {
    state.race = race;
    state.race_id = race?.id;
  },
  setDocument: (state, document_attributes) => {
    state.document_attributes = document_attributes;
  },
  setDeficiencie: (state, deficiencie) => {
    state.deficiencie = deficiencie;
    state.deficiencie_id = deficiencie?.id;
  },
  setWorkPermit: (state, workPermit) => {
    state.document_attributes.work_permit = workPermit;
  },
  setVoterTitle: (state, voter_title) => {
    state.document_attributes.voter_title = voter_title;
  },
  setReserveCard: (state, reserve_card) => {
    state.document_attributes.reserve_card = reserve_card;
  },
  setEquipment: (state, equipment) => {
    state.equipment = equipment;
  },
  setAgency: (state, agency) => {
    state.agency = agency;
  },
  setAutomaticArea: (state, automatic_area) => {
    state.automatic_area = automatic_area;
  },
  setAccount: (state, account) => {
    state.account = account;
  },
  setAccountDigit: (state, account_digit) => {
    state.account_digit = account_digit;
  },
  setYearOfLastEnrollment: (state, year_of_last_enrollment) => {
    state.year_of_last_enrollment = year_of_last_enrollment;
  },
  setDegreeKinship: (state, payload) => {
    let index = payload.index;
    let degreeKinship = { id: payload.id, name: payload.name };

    state.relatives_attributes.forEach((relative, i) => {
      if (index === i) {
        relative.degree_kinship = degreeKinship;
      }
    });
  },
  setResponsible: (state, payload) => {
    state.relatives_attributes.forEach((relative, i) => {
      relative.responsible = i === payload.value;
    });
  },
  setReferencePerson: (state, payload) => {
    state.relatives_attributes.forEach((relative, i) => {
      relative.reference_person = i === payload.value;
    });
  },
  setRelativeName: (state, payload) => {
    let index = payload.index;
    let name = payload.value;

    state.relatives_attributes.forEach((relative, i) => {
      if (index === i) {
        relative.name = name;
      }
    });
  },
  setRelativeRg: (state, payload) => {
    let index = payload.index;
    let rg = payload.value;

    state.relatives_attributes.forEach((relative, i) => {
      if (index === i) {
        relative.rg = rg;
      }
    });
  },
  setRelativeCpf: (state, payload) => {
    let index = payload.index;
    let cpf = payload.value;

    state.relatives_attributes.forEach((relative, i) => {
      if (index === i) {
        relative.cpf = cpf;
      }
    });
  },
  setRelativeCellphoneNumber: (state, payload) => {
    let index = payload.index;
    let cellphoneNumber = payload.value;

    state.relatives_attributes.forEach((relative, i) => {
      if (index === i) {
        relative.cellphone_number = cellphoneNumber;
      }
    });
  },
  setRelativeEmail: (state, payload) => {
    let index = payload.index;
    let email = payload.value;
    state.relatives_attributes.forEach((relative, i) => {
      if (index === i) {
        relative.email = email;
      }
    });
  },
  setRelativeBirthDate: (state, payload) => {
    let index = payload.index;
    let birth_date = payload.value;
    state.relatives_attributes.forEach((relative, i) => {
      if (index === i) {
        relative.birth_date = birth_date;
      }
    });
  },
  setRelativeSpecification: (state, payload) => {
    let index = payload.index;
    let specification = payload.value;
    state.relatives_attributes.forEach((relative, i) => {
      if (index === i) {
        relative.specification = specification;
      }
    });
  },
  addBirthdate: (state, birthdate) => {
    state.birthdate = birthdate;
  },
  addProfessionalQualification: (state, professionalQualification) => {
    state.professional_qualifications_attributes.push(professionalQualification);
  },
  addSport: (state, sport) => {
    state.sports_attributes.push(sport);
  },
  addTeam: (state, teams) => {
    state.teams.push(teams);
  },
  addRelative: (state) => {
    state.relatives_attributes.push({
      degree_kinship: null,
      responsible: null,
      reference_person: null,
      name: null,
      rg: null,
      cpf: null,
      cellphone_number: null,
      email: null,
      birth_date: null,
      specification: null,
    });
  },
  deleteRelative: (state) => {
    state.relatives_attributes.pop({
      degree_kinship: null,
      responsible: null,
      reference_person: null,
      name: null,
      rg: null,
      cpf: null,
      cellphone_number: null,
      email: null,
      birth_date: null,
      specification: null,
    });
  },
  removeProfessionalQualification: (state, professionalQualification) => {
    state.professional_qualifications_attributes = state.professional_qualifications_attributes.filter(
      (pq) => {
        return pq.id !== professionalQualification.id;
      }
    );
  },
  removeSport: (state, sport) => {
    state.sports_sports = state.sports_sports.filter((s) => {
      return s.id !== sport.id;
    });
  },
  removeTeam: (state, teams) => {
    state.teams_teams = state.teams_teams.filter((t) => {
      return t.id !== teams.id;
    });
  },
  clearProfessionalQualifications: (state) => {
    state.professional_qualifications_attributes = [];
  },
  clearSports: (state) => {
    state.sports_attributes = [];
  },
  clearTeams: (state) => {
    state.teams_attributes = [];
  },
  removeFromTeam: (state, payload) => {
    state.teams_attributes.forEach((item, index) => {
      item.students.forEach((value, indexStudent) => {
        if (value.id == payload.student.id) {
          state.teams_attributes[index].students.splice(indexStudent, 1);
        }
      });
    });
  },
  setErrorMessages: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
  addErrorMessages: (state, errorMessages) => {
    state.errorMessages.push(errorMessages);
  },
  setNumberSons: (state, number_sons) => {
    state.number_sons = number_sons;
  },
  setHasSons: (state, has_sons) => {
    state.has_sons = has_sons;
  },
  setSeasonActive: (state, season_active) => {
    state.season_active = season_active;
  },
  setStudents: (state, students) => {
    state.students = students;
    state.filterStudents = students;
  },
  filterList: (state, input) => {
    let students = state.students;
    state.filterStudents = students.filter((obj) => {
      let name = obj.name.toLowerCase().indexOf(input.value.toLowerCase());
      // let cpf = obj.cpf.toLowerCase().indexOf(input.value.toLowerCase());
      return name > -1;
    });
  },
};

const actions = {
  setStudents: ({ commit }, students) => {
    commit("setStudents", students);
  },
  filterList: ({ commit }, payload) => {
    commit("filterList", payload);
  },
  loadStudents: ({ commit }, payload) => {
    getStudentsByCityId(payload)
      .then((response) => {
        commit("setStudents", response.data.students);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
        console.log("error", e);
      });
  },
  loadSeasons: ({ commit }) => {
    getPartialsSeasons()
      .then((response) => {
        // let season;
        // const myDate = new Date(Date.now());
        // response.data.seasons.forEach((item) => {
        //   let date_inscription_start = new Date(item.date_inscription_start);
        //   let date_inscription_end = new Date(item.date_inscription_end);
        //   item.cities_attributes.forEach((city) => {
        //     if (city.id === payload && myDate <= date_inscription_end && myDate >= date_inscription_start) {
        //       season = item;
        //     }
        //   });
        // });
        commit("loadSeasons", response.data.seasons);
        // if (season) {
        //   commit("setSeason", season);
        // }
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadYears: async ({ commit }) => {
    await getYears()
      .then((response) => {
        commit("loadYears", response.data.years);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadYearsOfLastEnrollment: async ({ commit }) => {
    await getYearsForLastEnrollment()
      .then((response) => {
        commit("loadYearsOfLastEnrollment", response.data.years_for_last_enrollment);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadCivilStates: ({ commit }) => {
    getCivilStates()
      .then((response) => {
        commit("loadCivilStates", response.data.civil_states);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadCompletedSeries: ({ commit }) => {
    getCompletedSeries()
      .then((response) => {
        commit("loadCompletedSeries", response.data.completed_series);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadShirtSizes: ({ commit }) => {
    getShirtSizes()
      .then((response) => {
        commit("loadShirtSizes", response.data.shirt_sizes);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadBankNames: ({ commit }) => {
    getBankNames()
      .then((response) => {
        commit("loadBankNames", response.data.bank_name);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadAccountTypes: ({ commit }) => {
    getAccountTypes()
      .then((response) => {
        commit("loadAccountTypes", response.data.account_type);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadAccountFormats: ({ commit }) => {
    getAccountFormats()
      .then((response) => {
        commit("loadAccountFormats", response.data.account_format);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadEducationalStatus: ({ commit }) => {
    getEducationalStatus()
      .then((response) => {
        commit("loadEducationalStatus", response.data.educational_status);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadQualificationStatus: ({ commit }) => {
    getQualificationStatus()
      .then((response) => {
        commit("loadQualificationStatus", response.data.qualification_status);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadInstitutionalReferrals: ({ commit }) => {
    getInstitutionalReferrals()
      .then((response) => {
        commit("loadInstitutionalReferrals", response.data.institutional_referral);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadMonthPregnants: ({ commit }) => {
    getMonthPregnants()
      .then((response) => {
        commit("loadMonthPregnants", response.data.month_pregnant);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadFacilities: ({ commit }) => {
    const params = {
      forwarding_policy_id: 2,
      by: "facilities.name",
      order: "ASC",
    };
    getFacilities(params)
      .then((response) => {
        commit("loadFacilities", response.data.facilities);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadPantsSizes: ({ commit }) => {
    getPantsSizes()
      .then((response) => {
        commit("loadPantsSizes", response.data.pants_sizes);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadShoeSizes: ({ commit }) => {
    getShoeSizes()
      .then((response) => {
        commit("loadShoeSizes", response.data.shoe_sizes);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadStates: ({ commit }) => {
    getStates()
      .then((response) => {
        commit("loadStates", response.data.states);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadCities: ({ commit }, state_id) => {
    getCitiesByStateId(state_id)
      .then((response) => {
        commit("loadCities", response.data.cities);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadNeighborhoods: ({ commit }, payload) => {
    const params = {
      city_id: payload.city_id ? payload.city_id : payload,
    };
    getNeighborhoodsForMenus(params)
      .then((response) => {
        commit("loadNeighborhoods", response.data.neighborhoods);

        if (payload.default_neighborhood) {
          const neighborhood = response.data.neighborhoods.filter((obj) => {
            return obj.name === payload.default_neighborhood;
          })[0];

          commit("setNeighborhood", neighborhood);
        }
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadEquipment: ({ commit }, city_id) => {
    getEquipmentByCityId(city_id)
      .then((response) => {
        commit("loadEquipment", response.data.equipment);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadProfessionalQualifications: ({ commit }, city_id) => {
    getProfessionalQualificationByCityId(city_id)
      .then((response) => {
        commit("loadProfessionalQualifications", response.data.professional_qualifications);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadProfessionalQualificationsWithName: ({ commit, state }) => {
    getProfessionalQualificationsWithName()
      .then((response) => {
        const filteredQualifications = response.data.professional_qualifications.filter((qualification) => {
          return !(
            (state.first_option_area_of_interest &&
              qualification.id === state.first_option_area_of_interest.id) ||
            (state.second_option_area_of_interest &&
              qualification.id === state.second_option_area_of_interest.id) ||
            (state.third_option_area_of_interest &&
              qualification.id === state.third_option_area_of_interest.id)
          );
        });
        commit("loadProfessionalQualificationsWithName", filteredQualifications);
      })
      .catch((e) => {
        console.log("error", e.response); // TODO: tratar esse erro
      });
  },
  loadSports: ({ commit }, city_id) => {
    getSportByCityId(city_id)
      .then((response) => {
        commit("loadSports", response.data.sports);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadDegreeKinships: ({ commit }) => {
    getDegreeKinships()
      .then((response) => {
        commit("loadDegreeKinships", response.data.degree_kinships);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadNumbersSons: ({ commit }) => {
    let numbersSons = [
      { id: 0, name: "NÃO" },
      // { id: 1, name: "1" },
      // { id: 2, name: "2" },
      // { id: 3, name: "3" },
      // { id: 4, name: "4" },
      // { id: 5, name: "5" },
      { id: 6, name: "SIM" },
    ];
    commit("loadNumbersSons", numbersSons);
  },
  setStudent: ({ commit }, payload) => {
    commit("setStudent", payload);
  },
  setSeason: ({ commit }, payload) => {
    commit("setSeason", payload);
  },
  setState: ({ commit, dispatch }, payload) => {
    commit("setState", payload);
    dispatch("loadCities", payload.id);
    commit("loadNeighborhoods", []);
    commit("loadSeasons", []);
    commit("loadEquipment", []);
    commit("loadProfessionalQualifications", []);
    commit("loadSports", []);
    commit("loadTeams", []);

    commit("setNeighborhood", null);
    commit("setCity", null);
    commit("setSeason", null);
    commit("setEquipment", null);
    commit("clearProfessionalQualifications");
    commit("clearSports");
  },
  setCity: ({ commit, dispatch }, payload) => {
    commit("setCity", payload);
    commit("setNeighborhood", null);
    commit("setSeason", null);
    commit("setEquipment", null);
    commit("clearProfessionalQualifications");
    commit("clearSports");

    dispatch("loadNeighborhoods", payload.id);
    dispatch("loadSeasons", payload.id);
    dispatch("loadEquipment", payload.id);
    dispatch("loadProfessionalQualifications", payload.id);
    dispatch("loadSports", payload.id);
    dispatch("loadTeams", payload.id);
  },
  setAvatar: ({ commit }, payload) => {
    commit("setAvatar", payload);
  },
  setName: ({ commit }, payload) => {
    commit("setName", payload.value);
  },
  setSocialName: ({ commit }, payload) => {
    commit("setSocialName", payload.value);
  },
  setCumulativeFrequency: ({ commit }, payload) => {
    commit("setCumulativeFrequency", payload);
  },
  setRg: ({ commit }, payload) => {
    commit("setRg", payload.value);
  },
  setDispatchingAgency: ({ commit }, payload) => {
    commit("setDispatchingAgency", payload.value);
  },
  setExpedition: ({ commit }, payload) => {
    commit("setExpedition", payload.value);
  },
  setCpf: ({ commit }, payload) => {
    commit("setCpf", payload.value);
  },
  setCityOfBirth: ({ commit }, payload) => {
    commit("setCityOfBirth", payload.value);
  },
  setBook: ({ commit }, payload) => {
    commit("setBook", payload.value);
  },
  setPage: ({ commit }, payload) => {
    commit("setPage", payload.value);
  },
  setNis: ({ commit }, payload) => {
    commit("setNis", payload.value);
  },
  setEmail: ({ commit }, payload) => {
    commit("setEmail", payload.value);
  },
  setCellphoneNumber: ({ commit }, payload) => {
    commit("setCellphoneNumber", payload.value);
  },
  setWhatszap: ({ commit }, payload) => {
    commit("setWhatszap", payload.value);
  },
  setDay: ({ commit, state }, payload) => {
    commit("setDay", payload);
    if (state.day && state.month && state.year) {
      let birthdate = [state.year.name, state.month.id, state.day.name].join("-");
      commit("setBirthdate", birthdate);
    }
  },
  setMonth: ({ commit, state }, payload) => {
    commit("setMonth", payload);
    if (state.day && state.month && state.year) {
      let birthdate = [state.year.name, state.month.id, state.day.name].join("-");
      commit("setBirthdate", birthdate);
    }
  },
  setYear: ({ commit, state }, payload) => {
    commit("setYear", payload);
    if (state.day && state.month && state.year) {
      let birthdate = [state.year.name, state.month.id, state.day.name].join("-");
      commit("setBirthdate", birthdate);
    }
  },
  setBirthCertificate: ({ commit }, payload) => {
    commit("setBirthCertificate", payload.value);
  },
  setGender: ({ commit }, payload) => {
    commit("setGender", payload.value);
  },
  setIdentifiesAsLgbtqiapn: ({ commit }, payload) => {
    commit("setIdentifiesAsLgbtqiapn", payload.value);
    if (payload.value && payload.value !== "Sim") {
      commit("setSocialName", null);
    }
  },
  setBelongsToIndigenousPeoples: ({ commit }, payload) => {
    commit("setBelongsToIndigenousPeoples", payload.value);
  },
  setCivilState: ({ commit }, payload) => {
    commit("setCivilState", payload);
  },
  setCep: ({ commit, state, dispatch }, payload) => {
    let isCepEqual = state.cep != payload.value ? true : false;
    let cep = payload.value;
    if (!isCepEqual) {
      if (cep.length === 9) {
        const regex = /\d+/g;
        getAdress(cep.match(regex).join(""))
          .then((response) => {
            let data = response.data;

            const city = state.cities.filter((obj) => {
              return obj.name == data.localidade;
            })[0];
            if (data.erro) {
              commit("addErrorMessages", {
                attribute: "student.cep",
                message: "CEP inválido",
                type: "invalid_cep",
              });
              commit("setNeighborhood", {});
              cep = "";
            } else {
              dispatch("loadNeighborhoods", { city_id: city.id, default_neighborhood: data.bairro });
              let removeError = state.errorMessages.filter((obj) => {
                return obj.message !== "CEP inválido";
              });
              commit("setErrorMessages", removeError);
              commit("setNeighborhood", {});
            }

            commit("setStreet", data.logradouro);
            commit("setComplement", data.complemento);
            dispatch("setCity", city);
            commit("setCep", cep);
          })
          .catch((e) => {
            console.log("error", e); //TODO: tratar esse error
          });
      }
    }
    commit("setCep", cep);
  },
  setStreet: ({ commit }, payload) => {
    commit("setStreet", payload.value);
  },
  setStreetNumber: ({ commit }, payload) => {
    commit("setStreetNumber", payload.value);
  },
  setCommunity: ({ commit }, payload) => {
    commit("setCommunity", payload.value);
  },
  setComplement: ({ commit }, payload) => {
    commit("setComplement", payload.value);
  },
  setStudying: ({ commit }, payload) => {
    commit("setStudying", payload.value);
  },
  setIsPregnant: ({ commit }, payload) => {
    commit("setIsPregnant", payload.value);

    if (payload.value === false) {
      commit("setMonthPregnant", null);
    }
  },
  setBusy: ({ commit }, payload) => {
    commit("setBusy", payload.value);
  },
  setReserveCard: ({ commit }, payload) => {
    commit("setReserveCard", payload.value);
  },
  setVoterTitle: ({ commit }, payload) => {
    commit("setVoterTitle", payload.value);
  },
  setWorkPermit: ({ commit }, payload) => {
    commit("setWorkPermit", payload.value);
  },
  setManualArea: ({ commit }, payload) => {
    commit("setManualArea", payload);
  },
  setRace: ({ commit }, payload) => {
    commit("setRace", payload);
  },
  setDeficiencies: ({ commit }, payload) => {
    commit("setDeficiencies", payload);
  },
  setCompletedSeries: ({ commit }, payload) => {
    commit("setCompletedSeries", payload);
  },
  setProfessionalQualifications: ({ commit }, payload) => {
    commit("setProfessionalQualifications", payload);
  },
  setSports: ({ commit }, payload) => {
    commit("setSports", payload);
  },
  setTeams: ({ commit }, payload) => {
    commit("setTeams", payload);
  },
  setShift: ({ commit, state }, payload) => {
    switch (payload.value) {
      case "0":
        commit("setNightShift", !state.night_shift);
        break;
      case "1":
        commit("setAfternoonShift", !state.afternoon_shift);
        break;
      case "2":
        commit("setMorningShift", !state.morning_shift);
        break;
    }
  },
  setHeight: ({ commit }, payload) => {
    commit("setHeight", payload.value);
  },
  setWeight: ({ commit }, payload) => {
    commit("setWeight", payload.value);
  },
  setShirtSize: ({ commit }, payload) => {
    commit("setShirtSize", payload);
  },
  setBankName: ({ commit }, payload) => {
    commit("setBankName", payload);
  },
  setEducationalStatus: ({ commit }, payload) => {
    commit("setEducationalStatus", payload);
  },
  setQualificationStatus: ({ commit }, payload) => {
    commit("setQualificationStatus", payload);
  },
  setFirstOptionAreaOfInterest: ({ commit }, payload) => {
    commit("setFirstOptionAreaOfInterest", payload);
  },
  setSecondOptionAreaOfInterest: ({ commit }, payload) => {
    commit("setSecondOptionAreaOfInterest", payload);
  },
  setThirdOptionAreaOfInterest: ({ commit }, payload) => {
    commit("setThirdOptionAreaOfInterest", payload);
  },
  setInstitutionalReferral: ({ commit }, payload) => {
    commit("setInstitutionalReferral", payload);
  },
  setMonthPregnant: ({ commit }, payload) => {
    commit("setMonthPregnant", payload);
  },
  setLastSchoolStudied: ({ commit }, payload) => {
    commit("setLastSchoolStudied", payload);
  },
  setYearOfLastEnrollment: ({ commit }, payload) => {
    commit("setYearOfLastEnrollment", payload);
  },
  setAccountType: ({ commit }, payload) => {
    commit("setAccountType", payload);
  },
  setAccountFormat: ({ commit }, payload) => {
    commit("setAccountFormat", payload);
  },
  setAutomaticArea: ({ commit }, payload) => {
    commit("setAutomaticArea", payload);
  },
  setPantsSize: ({ commit }, payload) => {
    commit("setPantsSize", payload);
  },
  setShoeSize1: ({ commit }, payload) => {
    commit("setShoeSize1", payload);
  },
  setShoeSize2: ({ commit }, payload) => {
    commit("setShoeSize2", payload);
  },
  setAgency: ({ commit }, payload) => {
    commit("setAgency", payload.value);
  },
  setAccount: ({ commit }, payload) => {
    commit("setAccount", payload.value);
  },
  setAccountDigit: ({ commit }, payload) => {
    commit("setAccountDigit", payload.value);
  },
  setRelatives: ({ commit }, payload) => {
    commit("setRelatives", payload);
  },
  setNeighborhood: ({ commit, dispatch }, payload) => {
    commit("setNeighborhood", payload);

    if (state.neighborhood_id) {
      const params = {
        neighborhood_id: state.neighborhood_id,
      };
      getAreasForMenus(params)
        .then((response) => {
          if (response.data.areas && !state.student_area_exceptions_attributes.manual_override) {
            dispatch("setManualArea", response.data.areas);
          }
        })
        .catch((e) => {
          console.log("error", e.response);
        });
    }
  },
  setEquipment: ({ commit }, payload) => {
    commit("setEquipment", payload);
  },
  setDegreeKinship: ({ commit }, payload) => {
    commit("setDegreeKinship", payload);
  },
  setRelativeName: ({ commit }, payload) => {
    commit("setRelativeName", payload);
  },
  setRelativeRg: ({ commit }, payload) => {
    commit("setRelativeRg", payload);
  },
  setRelativeCpf: ({ commit }, payload) => {
    commit("setRelativeCpf", payload);
  },
  setRelativeCellphoneNumber: ({ commit }, payload) => {
    commit("setRelativeCellphoneNumber", payload);
  },
  setRelativeEmail: ({ commit }, payload) => {
    commit("setRelativeEmail", payload);
  },
  setRelativeBirthDate: ({ commit }, payload) => {
    commit("setRelativeBirthDate", payload);
  },
  setRelativeSpecification: ({ commit }, payload) => {
    commit("setRelativeSpecification", payload);
  },
  setResponsible: ({ commit }, payload) => {
    commit("setResponsible", payload);
  },
  setReferencePerson: ({ commit }, payload) => {
    commit("setReferencePerson", payload);
  },
  setHasSons: ({ commit }, payload) => {
    commit("setHasSons", payload.value);
  },
  setErrorMessages: ({ commit }, payload) => {
    commit("setErrorMessages", payload);
  },
  addRelative: ({ commit }) => {
    commit("addRelative");
  },
  deleteRelative: ({ commit }) => {
    commit("deleteRelative");
  },
  removeFromTeam: ({ commit }, payload) => {
    commit("removeFromTeam", payload);
  },
  addBirthdate: ({ commit }, payload) => {
    commit("setBirthdate", payload.value);
  },
  //FINALIZAR
  load: ({ commit }, payload) => {
    if (payload && !isEmpty(payload)) {
      commit("setSeason", payload.season);
      commit("setEquipment", payload?.equipment);
      commit("setId", payload.id);
      commit("setName", payload.name);
      commit("setAge", payload.age);
      commit("setSocialName", payload.social_name);
      commit("setCumulativeFrequency", payload.cumulative_frequency);
      commit("setRg", payload.rg);
      commit("setDispatchingAgency", payload.dispatching_agency);
      commit("setExpedition", payload.expedition);
      commit("setCpf", payload.cpf);
      commit("setCityOfBirth", payload.city_of_birth);
      commit("setBook", payload.book);
      commit("setPage", payload.page);
      commit("setNis", payload.nis);
      commit("setRace", payload.race);
      commit("setDeficiencies", payload.deficiencies_attributes ? payload.deficiencies_attributes : []);
      commit("setDocument", payload.document_attributes);
      commit("setStudentAreaException", payload.student_area_exceptions_attributes);
      commit("setEmail", payload.email);
      commit("setCellphoneNumber", payload.cellphone_number);
      commit("setWhatszap", payload.whatszap);
      commit("setBirthdate", payload.birthdate);
      commit("setBirthCertificate", payload.birth_certificate);
      commit("setGender", payload.gender);
      commit("setIdentifiesAsLgbtqiapn", payload.identifies_as_lgbtqiapn);
      commit("setQualificationStatus", payload.qualification_status);
      commit("setBelongsToIndigenousPeoples", payload.belongs_to_indigenous_peoples);
      commit("setCivilState", payload.civil_state);
      commit("setCep", payload.cep);
      commit("setStreet", payload.street);
      commit("setStreetNumber", payload.street_number);
      commit("setCommunity", payload.community);
      commit("setComplement", payload.complement);
      commit("setState", payload.state);
      commit("setStudying", payload.studying);
      commit("setIsPregnant", payload.is_pregnant);
      commit("setBusy", payload.busy);
      commit("setCompletedSeries", payload.completed_series);
      commit("setCity", payload.city);
      commit("setNeighborhood", payload.neighborhood);
      commit("setAutomaticArea", payload.neighborhood?.area);
      commit("setManualArea", payload?.student_area_exceptions_attributes?.area);
      commit("setProfessionalQualifications", payload.professional_qualifications_attributes);
      commit("setSports", payload.sports_attributes);
      commit("setTeams", payload.teams_attributes);
      commit("setMorningShift", payload.morning_shift);
      commit("setAfternoonShift", payload.afternoon_shift);
      commit("setNightShift", payload.night_shift);
      commit("setHeight", payload.height);
      commit("setWeight", payload.weight);
      commit("setShirtSize", payload.shirt_size);
      commit("setBankName", payload.bank_name);
      commit("setFirstOptionAreaOfInterest", payload.first_option_area_of_interest);
      commit("setSecondOptionAreaOfInterest", payload.second_option_area_of_interest);
      commit("setThirdOptionAreaOfInterest", payload.third_option_area_of_interest);
      commit("setEducationalStatus", payload.educational_status);
      commit("setQualificationStatus", payload.qualification_status);
      commit("setInstitutionalReferral", payload.institutional_referral);
      commit("setMonthPregnant", payload.month_pregnant);
      commit("setLastSchoolStudied", payload.last_school_studied);
      commit("setAccountType", payload.account_type);
      commit("setAccountFormat", payload.account_format);
      commit("setPantsSize", payload.pants_size);
      commit("setShoeSize1", payload.shoe_size1);
      commit("setShoeSize2", payload.shoe_size2);
      commit("setRelatives", payload.relatives_attributes);
      commit("setNumberSons", payload.number_sons);
      commit("setHasSons", payload.has_sons);
      commit("setAgency", payload.agency);
      commit("setAccount", payload.account);
      commit("setAccountDigit", payload.account_digit);
      commit("setYearOfLastEnrollment", payload.year_of_last_enrollment);
      commit("setLastTeamInformations", payload.last_team);
      commit("setProductiveInsertion", payload.productive_insertion);
      commit("setAllCodesTeams", payload.all_teams);
    } else {
      commit("setId", null);
      commit("setSeason", null);
      commit("setEquipment", null);
      commit("setName", null);
      commit("setAge", null);
      commit("setDay", null);
      commit("setMonth", null);
      commit("setYear", null);
      commit("setSocialName", null);
      commit("setCumulativeFrequency", null);
      commit("setRg", null);
      commit("setDispatchingAgency", null);
      commit("setExpedition", null);
      commit("setCpf", null);
      commit("setCityOfBirth", null);
      commit("setBook", null);
      commit("setPage", null);
      commit("setNis", null);
      commit("setRace", null);
      commit("setDeficiencies", null);
      commit("setDocument", { id: null, work_permit: "", voter_title: "", reserve_card: "" });
      commit("setStudentAreaException", { id: null, area_id: null });
      commit("setManualArea", null);
      commit("setEmail", null);
      commit("setCellphoneNumber", null);
      commit("setWhatszap", null);
      commit("setBirthdate", null);
      commit("setBirthCertificate", null);
      commit("setGender", null);
      commit("setIdentifiesAsLgbtqiapn", null);
      commit("setBelongsToIndigenousPeoples", null);
      commit("setCivilState", null);
      commit("setCep", null);
      commit("setStreet", null);
      commit("setStreetNumber", null);
      commit("setCommunity", null);
      commit("setComplement", null);
      commit("setState", { id: 1, name: "Ceará" });
      commit("setStudying", null);
      commit("setIsPregnant", null);
      commit("setBusy", null);
      commit("setCompletedSeries", null);
      commit("setCity", null);
      commit("setNeighborhood", null);
      commit("setAutomaticArea", null);
      commit("setProfessionalQualifications", []);
      commit("setSports", []);
      commit("setTeams", []);
      commit("setMorningShift", false);
      commit("setAfternoonShift", false);
      commit("setNightShift", false);
      commit("setHeight", null);
      commit("setWeight", null);
      commit("setShirtSize", null);
      commit("setBankName", null);
      commit("setEducationalStatus", null);
      commit("setQualificationStatus", null);
      commit("setInstitutionalReferral", null);
      commit("setMonthPregnant", null);
      commit("setLastSchoolStudied", null);
      commit("setAccountType", null);
      commit("setPantsSize", null);
      commit("setShoeSize1", null);
      commit("setShoeSize2", null);
      commit("setAgency", null);
      commit("setAccount", null);
      commit("setAccountDigit", null);
      commit("setYearOfLastEnrollment", null);
      commit("setProductiveInsertion", null);
      commit("setFirstOptionAreaOfInterest", null);
      commit("setSecondOptionAreaOfInterest", null);
      commit("setThirdOptionAreaOfInterest", null);
      commit("setRelatives", [
        {
          degree_kinship: null,
          responsible: null,
          reference_person: null,
          name: null,
          rg: null,
          cpf: null,
          cellphone_number: null,
          email: null,
          birth_date: null,
          specification: null,
        },
      ]);
      commit("setNumberSons", null);
      commit("setHasSons", null);
      commit("setLastTeamInformations", null);
      commit("setAllCodesTeams", []);
    }
  },
  setNumberSons: ({ commit }, payload) => {
    commit("setNumberSons", payload);
  },
};

const getters = {
  getStudentForAPI: (state, getters, rootState) => {
    const genderParse = {
      Feminino: "female",
      Masculino: "male",
      "Não-Binárie": "non_binary",
      Outros: "others",
      "Prefiro não Falar": "prefer_not_to_talk",
    };

    const identifiesAsLgbtqiapnParse = {
      Sim: "yes_s",
      Não: "no_o",
      "Prefiro não Falar": "prefer_not_to_talk",
    };

    const belongsToIndigenousPeoplesParse = {
      Não: "no",
      Indígena: "indigenous",
      Quilombola: "quilombola",
      Cigano: "gypsy",
      Outros: "others",
    };

    const student = rootState.Student;

    let relatives_attributes = [...student.relatives_attributes];

    relatives_attributes = relatives_attributes.map((relative) => {
      return {
        ...relative,
        degree_kinship: relative.degree_kinship?.id,
      };
    });
    let student_attributes = {
      ...student,
      gender: student.gender ? genderParse[student.gender] : null,
      identifies_as_lgbtqiapn: student.identifies_as_lgbtqiapn
        ? identifiesAsLgbtqiapnParse[student.identifies_as_lgbtqiapn]
        : null,
      belongs_to_indigenous_peoples: student.belongs_to_indigenous_peoples
        ? belongsToIndigenousPeoplesParse[student.belongs_to_indigenous_peoples]
        : null,
      state_id: student.state?.id,
      city_id: student.city?.id,
      neighborhood_id: student.neighborhood?.id,
      civil_state: student.civil_state?.id,
      completed_series: student.completed_series?.id,
      pants_size: student.pants_size?.id,
      shirt_size: student.shirt_size?.id,
      bank_name: student.bank_name?.id,
      first_option_area_of_interest_id: student.first_option_area_of_interest?.id,
      second_option_area_of_interest_id: student.second_option_area_of_interest?.id,
      third_option_area_of_interest_id: student.third_option_area_of_interest?.id,
      educational_status: student.educational_status?.id,
      qualification_status: student.qualification_status?.id,
      institutional_referral: student.institutional_referral?.id,
      month_pregnant: student.month_pregnant?.id,
      last_school_studied_id: student.last_school_studied?.id,
      year_of_last_enrollment: student.year_of_last_enrollment?.id,
      account_type: student.account_type?.id,
      account_format: student.account_format?.id,
      shoe_size1: student.shoe_size1?.id,
      shoe_size2: student.shoe_size2?.id,
      relatives_attributes: relatives_attributes,
    };

    delete student_attributes.days;
    delete student_attributes.months;
    delete student_attributes.day;
    delete student_attributes.month;
    delete student_attributes.year;
    delete student_attributes.seasons;
    delete student_attributes.years;
    delete student_attributes.civilStates;
    delete student_attributes.states;
    delete student_attributes.cities;
    delete student_attributes.neighborhoods;
    delete student_attributes.equipmentAll;
    delete student_attributes.completedSeries;
    delete student_attributes.shirtSizes;
    delete student_attributes.bankNames;
    delete student_attributes.educationalStatus;
    delete student_attributes.qualificationStatus;
    delete student_attributes.institutionalReferral;
    delete student_attributes.monthPregnant;
    delete student_attributes.facilities;
    delete student_attributes.accountTypes;
    delete student_attributes.accountFormats;
    delete student_attributes.pantsSizes;
    delete student_attributes.shoeSizes;
    delete student_attributes.professionalQualifications;
    delete student_attributes.sports;
    delete student_attributes.teams;
    delete student_attributes.degreeKinships;
    delete student_attributes.errorMessages;
    delete student_attributes.state;
    delete student_attributes.city;
    delete student_attributes.neighborhood;
    delete student_attributes.season;
    delete student_attributes.equipment;

    return student_attributes;
  },
  getDay: (state) => (date) => {
    if (date) {
      let id = date.split("-")[2];

      return state.days.find((day) => {
        return String(day.id) === String(id);
      });
    } else {
      return state.day;
    }
  },
  getMonth: (state) => (date) => {
    if (date) {
      let id = date.split("-")[1];

      return state.months.find((month) => {
        return month.id === id;
      });
    } else {
      return state.month;
    }
  },
  getYear: (state) => (date) => {
    if (date) {
      let id = date.split("-")[0];
      return state.years.find((year) => {
        return String(year.id) === id;
      });
    } else {
      return state.year;
    }
  },
  getCivilState: (state) => (name) => {
    return state.civilStates.find((civilState) => {
      return civilState.name === name;
    });
  },
  getState: (state) => (id) => {
    return state.states.filter((state) => {
      return state.id === id;
    });
  },
  getCity: (state) => (id) => {
    return state.cities.filter((city) => {
      return city.id === id;
    });
  },
  getNeighborhood: (state) => (id) => {
    if (id) {
      return state.neighborhoods.filter((neighborhood) => {
        return neighborhood.id === id;
      });
    } else {
      return null;
    }
  },
  getSeason: (state) => (id) => {
    return state.seasons.filter((season) => {
      return season.id === id;
    });
  },
  getEquipament: (state) => (id) => {
    return state.equipmentAll.filter((equipament) => {
      return equipament.id === id;
    });
  },
  getCompletedSerie: (state) => (id) => {
    return state.completedSeries.filter((completed_serie) => {
      return completed_serie.id === id;
    });
  },
  getProfessionalQualifications: (state) => (professionalQualifications) => {
    let ids = professionalQualifications.map((professionalQualification) => {
      return professionalQualification.id;
    });
    return state.professionalQualifications.filter((professionalQualification) => {
      return ids.includes(professionalQualification.id);
    });
  },

  getSports: (state) => (sports) => {
    let ids = sports.map((sport) => {
      return sport.id;
    });

    return state.sports.filter((sport) => {
      return ids.includes(sport.id);
    });
  },
  getTeams: (state) => (teams) => {
    let ids = teams.map((teams) => {
      return teams.id;
    });

    return state.teams.filter((teams) => {
      return ids.includes(teams.id);
    });
  },
  getShirtSize: (state) => (id) => {
    return state.shirtSizes.filter((shirtSize) => {
      return shirtSize.id === id;
    });
  },
  getBankName: (state) => (id) => {
    return state.bankNames.filter((bankName) => {
      return bankName.id === id;
    });
  },
  getAccountType: (state) => (id) => {
    return state.accountTypes.filter((accountType) => {
      return accountType.id === id;
    });
  },
  getAccountFormat: (state) => (id) => {
    return state.accountFormats.filter((accountFormat) => {
      return accountFormat.id === id;
    });
  },
  getEducationalStatus: (state) => (id) => {
    return state.educationalStatus.filter((educationalStatus) => {
      return educationalStatus.id === id;
    });
  },
  getQualificationStatus: (state) => (id) => {
    return state.qualificationStatus.filter((qualificationStatus) => {
      return qualificationStatus.id === id;
    });
  },
  getInstitutionalReferral: (state) => (id) => {
    return state.institutionalReferral.filter((institutionalReferral) => {
      return institutionalReferral.id === id;
    });
  },
  getMonthPregnant: (state) => (id) => {
    return state.monthPregnant.filter((monthPregnant) => {
      return monthPregnant.id === id;
    });
  },
  getFacility: (state) => (id) => {
    return state.facilities.filter((facility) => {
      return facility.id === id;
    });
  },
  getPantsSize: (state) => (id) => {
    return state.pantsSizes.filter((pantsSize) => {
      return pantsSize.id === id;
    });
  },
  getShoeSize: (state) => (id) => {
    return state.shoeSizes.filter((shoeSize) => {
      return shoeSize.id === id;
    });
  },
  getDegreeKinship: (state) => (id) => {
    return state.degreeKinships.filter((degreeKinship) => {
      return degreeKinship.id === id;
    });
  },
  getErrorMessage: (state) => (key, field) => {
    let error = state.errorMessages.find((errorMessage) => {
      return errorMessage.attribute === key;
    });
    if (error) {
      switch (error.type) {
        case "blank":
          if (field === "" || field === " " || field === undefined || field === null || field.length === 0) {
            return error.message;
          } else {
            return null;
          }

        case "wrong_length":
          return error.message;

        case "invalid_cpf":
          return error.message;

        case "invalid_cep":
          return error.message;

        case "invalid":
          return error.message;

        case "taken":
          return error.message;

        case "inclusion":
          return error.message;

        case "required":
          return error.message;

        default:
          return null;
      }
    } else {
      return null;
    }
  },
  getNumbersSons: (state) => (id) => {
    return state.numbersSons.filter((number_sons) => {
      return number_sons.id === id;
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
