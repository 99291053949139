<template>
  <ul>
    <li v-for="(obj, index) in data" :key="index" class="m-2">
      <AppCardStudent :student="obj" :class="{ inactive: obj.status === 'inactive' }" />
      <div class="w-full border mt-1 border-ashes-hover"></div>
    </li>
  </ul>
</template>

<script>
import AppCardStudent from "../../components/AppCardStudent.vue";

export default {
  components: {
    AppCardStudent,
  },
  props: {
    data: Array,
  },
};
</script>

<style>
.inactive {
  background: #d9d9d9;
  box-shadow: none !important;
}
</style>
