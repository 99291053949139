<template>
  <div class="signin-login relative">
    <div class="top-36 left-28 absolute">
      <router-link class="text-herbal underline hover:text-herbal-hover" to="/">
        <BaseText text="< Voltar" type-text="custom" weight="medium" />
      </router-link>
    </div>
    <div class="flex flex-col justify-center items-center">
      <div class="my-8">
        <img src="../assets/images/logo-name-green.png" alt="Logo" />
      </div>
      <div class="md:mt-20 md:mb-10">
        <BaseText
          text="Área de Login"
          type-text="custom"
          weight="semibold"
          class="text-color-admin underline"
          size="xl"
        />
      </div>
      <div>
        <BaseText
          text="Faça seu login"
          type-text="custom"
          weight="semibold"
          class="text-color-admin"
          size="xl"
        />
      </div>
      <div class="w-full max-w-md my-2">
        <AppInputRound
          id="username"
          iconName="person"
          labelText="E-mail"
          textColor="herbal"
          borderColor="herbal"
          dispatch="Session/setUsername"
        />
      </div>
      <div class="w-full max-w-md my-2" @keypress.enter="logIn">
        <AppInputRound
          id="password"
          iconName="visibility_off"
          labelText="Senha"
          textColor="herbal"
          borderColor="herbal"
          hiddenValue
          dispatch="Session/setPassword"
        />
      </div>
      <div class="flex justify-start">
        <div class="pl-5 w-full max-w-md mt-0 mb-3">
          <div class="text-herbal underline hover:text-herbal-hover cursor-pointer" @click="forgotPassword">
            <BaseText
              text="Esqueceu a senha?"
              type-text="custom"
              weight="normal"
              class="text-color-admin"
              size="xs"
            />
          </div>
        </div>
      </div>
      <div class="my-2">
        <AppButtonColorful
          id="btn-access"
          text="Acessar"
          typeButton="primary"
          :payload="{ access: ['Administrador', 'Gerente de Área'] }"
          dispatch="Session/validateUser"
          class="w-full max-w-md"
        />
      </div>
    </div>
    <div class="bot w-full absolute bottom-0"></div>
    <ModalForgotPassword :open="openModalForgotPassword" />
  </div>
</template>

<script>
import BaseText from "../components/BaseText.vue";
import AppInputRound from "../components/AppInputRound.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import ModalForgotPassword from "./alerts/ForgotPassword.vue";
import { mapState } from "vuex";

export default {
  components: {
    BaseText,
    AppInputRound,
    AppButtonColorful,
    ModalForgotPassword,
  },
  created() {
    const url = this.$route.query.redirect || "/admin";
    this.$store.dispatch("Session/setRedirectUrl", url);
    this.$store.dispatch("Session/setLoggedUser", null);
    this.$store.dispatch("Session/setRole", null);
    localStorage.vuex = null;
    localStorage.token = null;
  },

  computed: {
    ...mapState({
      openModalForgotPassword: (state) => state.Default.modalForgotPassword,
    }),
  },
  methods: {
    forgotPassword() {
      this.$store.dispatch("Default/toggleModalForgotPassword");
    },
    logIn() {
      this.$store.dispatch("Session/validateUser", { access: ["Administrador", "Gerente de Área"] });
    },
  },
};
</script>

<style scoped>
.signin-login {
  background-image: url("../assets/images/background-sigin-admin.png");
  background-color: #dbe6de;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bot {
  background-image: url("../assets/images/img-rodape.png");
  height: 55px;
}

.text-color-admin {
  color: #328239;
}

@media (max-width: 768px) {
  .top-36 {
    left: 10px;
  }
  .signin-login {
    padding: 20px;
  }
}
</style>
