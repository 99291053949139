<template>
  <div id="edition" class="mx-12 my-6">
    <BaseCard active class="h-auto">
      <div
        class="flex flex-col lg:flex-row gap-4 justify-start items-start lg:justify-between lg:items-center"
      >
        <div class="flex flex-col gap-3">
          <!-- Título -->
          <Label
            :text="season.name"
            :icon="null"
            :iconType="null"
            type="text-xl"
            weight="extrabold"
            normal
            mode="normal-case"
          />

          <!-- Datas -->
          <div class="flex flex-row gap-5 justify-start items-center flex-wrap season_dates">
            <div class="flex flex-row items-center justify-center gap-2 py-1" title="Período de Inscrição">
              <i class="material-icons mt-1">edit_calendar</i>
              <div class="flex flex-col">
                <Label
                  :text="season.date_inscription_start"
                  :iconType="null"
                  type="text-xs"
                  icon="edit_calendar"
                  weight="medium"
                  normal
                  mode="uppercase"
                  class="text-ashes-dark"
                />
                <Label
                  :text="season.date_inscription_end"
                  :iconType="null"
                  type="text-xs"
                  icon="none"
                  weight="medium"
                  normal
                  mode="uppercase"
                  class="text-ashes-dark"
                />
              </div>
            </div>
            <div class="flex flex-row items-center justify-center gap-2 py-1" title="Período de Execução">
              <i class="material-icons mt-1">date_range</i>
              <div class="flex flex-col">
                <Label
                  :text="season.date_execution_start"
                  :iconType="null"
                  type="text-xs"
                  icon="none"
                  weight="medium"
                  normal
                  mode="uppercase"
                  class="text-ashes-dark"
                />
                <Label
                  :text="season.date_execution_end"
                  :iconType="null"
                  type="text-xs"
                  icon="none"
                  weight="medium"
                  normal
                  mode="uppercase"
                  class="text-ashes-dark"
                />
              </div>
            </div>
          </div>

          <!-- Cidades -->
          <div class="flex flex-col gap-3">
            <div class="flex flex-row gap-2 flex-wrap">
              <Label
                v-for="city in season.cities_attributes"
                :key="city.id"
                :text="city.name"
                :icon="null"
                :iconType="null"
                type="text-xs"
                weight="medium"
                normal
                mode="uppercase"
                class="badge"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <BaseButton
            v-if="permitFinishSeason && season.date_manual_execution_end === null"
            label="Encerrar Edição"
            active
            styleButton="danger"
            dispatch="Season/openCloseEndSeasonConfirmation"
            class="whitespace-nowrap"
          />
          <BaseButton
            v-if="permitReactivateSeason && season.date_manual_execution_end !== null"
            label="Reativar Edição"
            active
            styleButton="success"
            dispatch="Season/openCloseReactivateSeasonConfirmation"
            class="whitespace-nowrap"
          />
        </div>
      </div>

      <!-- <div class="grid grid-cols-2">
        <div>
          <Label
            :text="season.inscription_status"
            :iconType="null"
            type="text-xs"
            icon="none"
            weight="bold"
            normal
            mode="uppercase"
          />
        </div>
      </div>
      <div class="grid grid-cols-2">
        <div>
          <Label
            :text="season.execution_status"
            :iconType="null"
            type="text-xs"
            icon="none"
            weight="bold"
            normal
            mode="uppercase"
          />
        </div>
      </div> -->

      <!--    <div class="text-right" v-if="!season.date_manual_execution_end">-->
      <!--      <AppButtonIcon iconName="edit" @on-click.stop="openSeasonModal" v-if="permit" />-->
      <!--    </div>-->
    </BaseCard>
  </div>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import BaseButton from "./BaseButton.vue";
import Label from "./Label.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    BaseButton,
    Label,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permit: false,
      permitFinishSeason: false,
      permitReactivateSeason: false,
    };
  },
  created() {
    this.$store.dispatch("Season/loadSeason", this.season);
    this.permitChangeSeason();
    this.permitFinishSeasonAccess();
    this.permitReactivateSeasonAccess();
    this.$store.dispatch("Areas/loadAreas");
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    season: {
      type: Object,
    },
  },

  computed: {
    liClass() {
      return {
        "w-full": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        grid: true,
        "grid-cols-5": true,
        "gap-4": true,
        "content-center": true,
        "p-6": true,
        flex: true,
        "items-center": true,
      };
    },
  },

  methods: {
    openSeasonModal() {
      this.$emit("onClick", { season: this.season, edit: true });
    },
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    permitChangeSeason() {
      this.canAcces("season_update").then((resp) => {
        this.permit = resp;
      });
    },
    permitFinishSeasonAccess() {
      this.canAcces("season_end_season").then((response) => {
        this.permitFinishSeason = response;
      });
    },
    permitReactivateSeasonAccess() {
      this.canAcces("season_reactivate_season").then((response) => {
        this.permitReactivateSeason = response;
      });
    },
  },
};
</script>

<style>
.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  color: #000 !important;
  background-color: rgba(0, 0, 0, 0.1);
  line-height: 1em;
  text-align: center;
  white-space: nowrap;
  border-radius: 50em;
}

.season_dates > :first-child {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  padding-right: 20px;
}
</style>
