<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="neighborhood.id"
            text="Editar Bairro"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Criar Bairro"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Neighborhoods/closeModalAddNeighborhood" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2">
          <BaseInput
            id="Nome"
            input-name="name"
            input-type="text"
            show-label
            required
            label-text="Nome"
            :value="neighborhood.name"
            dispatch="Neighborhoods/setName"
            :error-message="getErrorMessage('nome')"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Município"
            :dispatch="setCity"
            required
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Área"
            :multiple-option="false"
            :value="area"
            :options="areas"
            label-text="Área"
            :dispatch="setArea"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="Neighborhoods/closeModalAddNeighborhood"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="neighborhood.id"
            text="Atualizar"
            typeButton="success"
            dispatch="Neighborhoods/update"
          />
          <AppButtonEmpty v-else text="Criar" typeButton="success" dispatch="Neighborhoods/save" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import Label from "@/components/Label.vue";
import AppButtonIcon from "@/components/AppButtonIcon.vue";
import AppButtonEmpty from "@/components/AppButtonEmpty.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  created() {
    // this.$store.dispatch("UtilFunctions/loadAreasForMenus");
  },

  components: {
    Multiselect,
    BaseModal,
    BaseInput,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      setState: "Neighborhoods/setState",
      setCity: "Neighborhoods/setCity",
      setArea: "Neighborhoods/setArea",
      clearFields: "UtilFunctions/clearFields",
    }),
    getLabelCity() {
      return this.state ? "Município" : "Selecione o estado para listar os municípios";
    },
  },
  computed: {
    ...mapState({
      neighborhood: (state) => state.Neighborhoods.neighborhood,
      state: (state) => state.Neighborhoods.state,
      city: (state) => state.Neighborhoods.city,
      area: (state) => state.Neighborhoods.area,
      states: (state) => state.UtilFunctions.states,
      cities: (state) => state.City.citiesAll,
      areas: (state) => state.UtilFunctions.areas_for_menus,
    }),
    ...mapGetters({
      getErrorMessage: "Neighborhoods/getErrorMessage",
    }),
  },
  watch: {
    open: {
      handler(newVal) {
        if (newVal) {
          if (!this.neighborhood.id) {
            this.clearFields();
          }
        }
      },
      immediate: true, // Dispara a função assim que o componente for montado
    },
    city(newCity) {
      if (newCity) {
        this.$store.dispatch("UtilFunctions/loadAreasForMenus", newCity);
      } else {
        this.setArea(null); // Reseta a área ao desmarcar a cidade
        this.clearFields(); // Limpa os campos do Vuex
      }
    },
  },
};
</script>
