import Vue from "vue";
import { destroyEquipment, getEquipments } from "../../service/EquipmentApi";

const state = {
  equipmentAll: [],
  filteredEquipmentAll: [],
  equipmentsTotal: 0,
  equipmentsTotalPerPage: 10,
  equipmentsOffset: 0,
  filters: {
    name: null,
    area: null,
    neighborhood: null,
    city: null,
    state: null,
  },
};

const mutations = {
  setEquipmentMutation: (state, equipment) => {
    state.equipmentAll = equipment;
  },
  setAreaFilterMutation: (state, area) => {
    state.filters.area = area;
  },
  setNeighborhoodFilterMutation: (state, neighborhood) => {
    state.filters.neighborhood = neighborhood;
  },
  setNameFilterMutation: (state, name) => {
    state.filters.name = name;
  },
  setCityFilterMutation: (state, city) => {
    state.filters.city = city;
  },
  setStateFilterMutation: (state, value) => {
    state.filters.state = value;
  },
  addEquipmentMutation: (state, equipment) => {
    state.filteredEquipmentAll.push(equipment);
  },
  // --> MUTATIONS - PAGINATION FIELDS <--
  setEquipmentsTotalPerPage: (state, equipmentsTotalPerPage) => {
    state.equipmentsTotalPerPage = equipmentsTotalPerPage;
  },
  setEquipmentsOffset: (state, equipmentsOffset) => {
    state.equipmentsOffset = equipmentsOffset;
  },
  setEquipmentsTotal: (state, equipmentsTotal) => {
    state.equipmentsTotal = equipmentsTotal;
  },
  // --> MUTATIONS - CLEARS <--
  clearFiltersMutation: (state) => {
    state.filters.area = null;
    state.filters.neighborhood = null;
    state.filters.city = null;
    state.filters.state = null;
    state.filters.name = null;
    state.equipmentAll = [];
  },
};

const actions = {
  loadEquipment: ({ commit }) => {
    const params = {
      name: state.filters.name,
      city_id: state.filters.city && state.filters.city.id ? state.filters.city.id : null,
      area_id: state.filters.area ? state.filters.area.id : null,
      neighborhood_id: state.filters.neighborhood ? state.filters.neighborhood.id : null,
      per_page: state.equipmentsTotalPerPage,
      offset: state.equipmentsOffset,
    };
    getEquipments(params)
      .then((response) => {
        commit("setEquipmentMutation", response.data.equipment);
        commit("setEquipmentsTotal", response.data.total);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  addEquipment: ({ commit }, payload) => {
    commit("addEquipmentMutation", payload);
  },
  filterNeighborhood: ({ commit }, neighborhood) => {
    commit("setNeighborhoodFilterMutation", neighborhood);
  },
  filterName: ({ commit }, payload) => {
    commit("setNameFilterMutation", payload.value);
  },
  filterCity: ({ commit }, payload) => {
    commit("setCityFilterMutation", payload);
    commit("setNeighborhoodFilterMutation", []);
  },
  filterState: ({ commit }, payload) => {
    commit("setStateFilterMutation", payload);
  },
  filterArea: ({ commit }, area) => {
    commit("setAreaFilterMutation", area);
  },
  // --> ACTIONS - PAGINATIONS <--
  setEquipmentsTotalPerPage: ({ commit }, payload) => {
    commit("setEquipmentsTotalPerPage", payload.totalPerPage);
  },
  setEquipmentsOffset: ({ commit }, payload) => {
    commit("setEquipmentsOffset", payload.offset);
  },
  clearFilters: ({ commit, dispatch }) => {
    commit("clearFiltersMutation");
    dispatch("Neighborhoods/setNeighborhoods", [], { root: true });
  },
  openCloseDeleteEquipmentConfirmation: ({ commit, state }, payload) => {
    commit("toggleModalDeleteEquipmentConfirmation");
    if (state.modalDeleteEquipmentConfirmationOpen) {
      commit("equipmentForDeletion", payload.id);
    } else {
      commit("equipmentForDeletion", null);
    }
  },
  deleteEquipment: ({ commit, state }) => {
    const equipment_id = state.equipmentIdForDeletion;
    destroyEquipment(equipment_id)
      .then((response) => {
        const equipment = state.equipmentAll.filter((e) => {
          return e.id !== equipment_id;
        });
        commit("loadEquipment", equipment);
        Vue.$toast.success(response.data.message);
        commit("toggleModalDeleteEquipmentConfirmation");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.errors);
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
