<template>
  <div class="p-6 bg-gray-50 rounded-lg shadow-md">
    <div v-if="!hasRemoveTeams" class="bg-yellow-100 p-4 rounded-md mb-4 text-yellow-800">
      <AppTextField :text="getInvalidTeamsMessage()" type-text="topic-details" />
    </div>
    <div v-if="hasRemoveTeams" class="grid grid-cols-2 items-center mb-4">
      <div class="ml-20">
        <BaseText
          text="Nome"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div>
        <div class="grid grid-cols-2">
          <div class="justify-self-end">
            <BaseText
              text="Nº de Estudantes"
              typeText="custom"
              weight="medium"
              :italic="false"
              mode="uppercase"
              size="xs"
              color="quantum"
            />
          </div>
          <div class="justify-self-center">
            <BaseText
              text="Período"
              typeText="custom"
              weight="medium"
              :italic="false"
              mode="uppercase"
              size="xs"
              color="quantum"
            />
          </div>
        </div>
      </div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <li :key="item.id" :data-index="index" v-for="(item, index) in data" class="mb-4">
        <BaseCard :class="liClass" v-if="checkTeamCode(item)">
          <div class="grid grid-cols-2 items-center pt-2">
            <div>
              <div class="flex justify-start items-center">
                <div class="mr-10">
                  <input
                    :id="item.code"
                    type="radio"
                    name="transferStudentSourceTeam"
                    :value="item.id"
                    @click="onCheck(item)"
                    :disabled="
                      !isValidClosingTeam(item) || !isTeamInProgress(item) || item.status === 'inactive'
                    "
                    class="cursor-pointer"
                  />
                </div>
                <div :class="getCSS(item)">
                  <AppMaterialIcon
                    iconName="book"
                    iconType="outlined"
                    class="place-self-center text-white"
                    iconSize="36"
                    :color="getColorFields(item)"
                  />
                </div>
                <BaseText
                  :text="item.code"
                  typeText="custom"
                  mode="underline"
                  weight="bold"
                  size="base"
                  class="mr-1"
                  :color="getColorFields(item)"
                />
                <BaseText
                  text="|"
                  typeText="custom"
                  weight="normal"
                  size="2xl"
                  class="mr-1"
                  :color="getColorFields(item)"
                />
                <BaseText
                  :text="item.discipline.name"
                  typeText="custom"
                  mode="underline"
                  weight="bold"
                  size="base"
                  class="mr-1"
                  :color="getColorFields(item)"
                />
              </div>
            </div>
            <div class="justify-self-end">
              <div class="grid grid-cols-2">
                <div>
                  <BaseText
                    :text="`${item.qtd_students}`"
                    typeText="custom"
                    size="sm"
                    :color="getColorFields(item)"
                  />
                </div>
                <div>
                  <BaseText
                    :text="`${item.start} - ${item.closing}`"
                    typeText="custom"
                    size="sm"
                    :color="getColorItemClosing(item)"
                  />
                </div>
              </div>
            </div>
          </div>
        </BaseCard>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";
import AppMaterialIcon from "../../components/AppMaterialIcon.vue";
import BaseCard from "../../components/BaseCard.vue";
import { mapState } from "vuex";
import AppTextField from "@/components/AppTextField.vue";

export default {
  components: {
    AppTextField,
    BaseText,
    BaseStaggeredFade,
    AppMaterialIcon,
    BaseCard,
  },
  computed: {
    ...mapState({
      loading: (state) => state.ModalExportTeamPresence.loading,
      inscription: (state) => state.Inscription,
      inscriptions: (state) => state.Season.inscriptions,
      teams: (state) => state.Teams.filteredAll,
      inscriptionFiltered: (state) => state.Season.inscriptionFiltered,
      phase_to_transfer_remove_between_teams: (state) => state.Season.phase_to_transfer_remove_between_teams,
      hasRemoveTeams: (state) => state.Season.hasRemoveTeams,
    }),
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        "p-6": true,
      };
    },
  },
  mounted() {
    this.calculateRemoveTeams();
  },
  props: {
    data: {
      type: Array,
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    getCSS(value) {
      let css = [];
      let bgColor = "";
      if (value.discipline.id === 1) {
        bgColor = "bg-yellow-300";
      }
      if (value.discipline.id === 2) {
        bgColor = "bg-burnedYellow-classic";
      }
      if (value.discipline.id === 3) {
        bgColor = "bg-green-primary";
      }
      if (value.discipline.id === 4) {
        bgColor = "bg-blue-primary";
      }
      if (value.discipline.id === 5) {
        bgColor = "bg-rose-primary";
      }
      css.push(bgColor);
      css.push("rounded-full");
      css.push("w-12");
      css.push("h-12");
      css.push("grid");
      css.push("mr-3");
      css.push("items-center");
      css.push("justify-center");
      css.push("min-w-1");

      return css;
    },
    checkTeamCode(value) {
      return (
        value.discipline.phase.code === this.phase_to_transfer_remove_between_teams.code &&
        value.status === "active"
      );
    },
    calculateRemoveTeams() {
      let removeTeams = false;
      this.data.forEach((item) => {
        if (this.checkTeamCode(item)) {
          removeTeams = true;
        }
      });
      this.$store.dispatch("Season/hasRemoveTeams", removeTeams);
    },
    onCheck(team) {
      this.$store.dispatch("Season/filterTeamToRemove", team);
    },
    getColorItemClosing(item) {
      return this.isValidClosingTeam(item) ? "ashes-dark" : "carmesim";
    },
    getColorFields(item) {
      return !this.isValidClosingTeam(item) || !this.isTeamInProgress(item) || item.status === "inactive"
        ? "ashes"
        : "ashes-dark";
    },
    isTeamFinishedMessage(item) {
      return this.isTeamInProgress(item) ? "" : "(Turma Encerrada)";
    },
    isValidClosingTeam(item) {
      if (!item?.closing) return false; // Verifica se `item.closing` existe

      const [day, month, year] = item.closing.split("/"); // Desestruturação para melhor legibilidade

      if (!day || !month || !year) return false; // Garante que os valores existem

      const formattedDate = new Date(`${year}-${month}-${day}`); // Formata a data corretamente

      return !isNaN(formattedDate) && formattedDate > new Date(); // Valida a data e compara com o momento atual
    },
    isTeamInProgress(item) {
      return item.in_progress;
    },
    getInvalidTeamsMessage() {
      return !this.hasRemoveTeams
        ? "Não há turmas de origem disponíveis / elegíveis para remoção nesta fase."
        : "";
    },
  },
  watch: {
    phase_to_transfer_remove_between_teams: {
      handler() {
        this.calculateRemoveTeams();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 56% 18% 26% 0;
}
</style>
