<template>
  <div class="w-full mt-4">
    <!-- Cabeçalho da Grid -->
    <div class="grid grid-cols-9 gap-2 border-b border-gray-300 pb-2">
      <div class="pl-7 col-span-2">
        <!-- Aqui a primeira coluna ocupa 2 das 7 colunas -->
        <BaseText text="Nome" typeText="custom" weight="medium" mode="uppercase" size="xs" color="quantum" />
      </div>
      <div>
        <BaseText
          class="pl-2"
          text="Qtd. Alunos(as)"
          typeText="custom"
          weight="medium"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div>
        <BaseText
          class="pl-1"
          text="Período"
          typeText="custom"
          weight="medium"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div>
        <BaseText text="Dias" typeText="custom" weight="medium" mode="uppercase" size="xs" color="quantum" />
      </div>
      <div>
        <BaseText text="Turno" typeText="custom" weight="medium" mode="uppercase" size="xs" color="quantum" />
      </div>
      <div>
        <BaseText
          text="Instrutor"
          typeText="custom"
          weight="medium"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div class="col-span-2">
        <BaseText
          text="Equipamento"
          typeText="custom"
          weight="medium"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
    </div>

    <!-- Conteúdo da Grid -->
    <BaseStaggeredFade :duration="10" tag="ul">
      <li v-for="(team, index) in data" :key="index" :data-index="index" @click.prevent="gotToTeam(team)">
        <AppCardTeams :team="team" />
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardTeams from "../../components/AppCardTeam.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";
import router from "../../router/index";

export default {
  components: {
    AppCardTeams,
    BaseText,
    BaseStaggeredFade,
  },

  props: {
    data: {
      type: Array,
    },
  },

  methods: {
    gotToTeam(params) {
      router.push({ name: "turma", params: { id: params.id } });
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  /* grid-template-columns: 45% 19% 10% 10% 15%; */
  grid-template-columns: 25% 20% 14% 11% 10%;
}
</style>
