<template>
  <div class="grid auto-grid-auto p-10">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-6 my-6">
      <div class="text-ashes-hover font-medium text-2xl col-span-2">
        <Label
          text="Usuários"
          :icon="null"
          :iconType="null"
          type="text-3xl"
          weight="semibold"
          normal
          mode="normal-case"
          :class="title"
        />
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          text="Adicionar usuário(a)"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="User/openModal"
          v-if="permit_create"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-6 mb-2">
      <FilterComponent>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="name"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="name"
            label-text="Filtrar por Nome do Usuário(a)"
            input-placeholder="Digite o Nome do Usuário(a)"
            dispatch="Users/setFilterName"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="email"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="email"
            label-text="Filtrar por Email do Usuário(a)"
            input-placeholder="Digite o Email do Usuário(a)"
            dispatch="Users/setFilterEmail"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="cpf"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="cpf"
            label-text="Filtrar por CPF do Usuário(a)"
            input-placeholder="Digite o CPF do Usuário(a)"
            dispatch="Users/setFilterCpf"
          />
        </div>
        <div>
          <Multiselect
            id="filterCity"
            selectPlaceholder="Selecionar Município"
            label-text="Filtrar por Município"
            class="mr-5"
            closeOnSelect
            show-label
            :multipleOption="false"
            :options="cities"
            :value="city"
            :dispatch="setFilterCity"
          />
        </div>
        <div>
          <Multiselect
            id="filterCity"
            selectPlaceholder="Selecionar Área"
            label-text="Filtrar por Área"
            class="mr-5"
            closeOnSelect
            show-label
            :multipleOption="false"
            :options="user_areas"
            :value="area"
            :dispatch="setFilterArea"
          />
        </div>
        <div>
          <Multiselect
            id="filterState"
            selectPlaceholder="Selecionar Status"
            label-text="Filtrar por Status"
            class="mr-5"
            closeOnSelect
            show-label
            :multipleOption="false"
            :options="statuses"
            :value="status"
            :dispatch="setFilterStatus"
          />
        </div>
        <div>
          <Multiselect
            id="filterState"
            selectPlaceholder="Selecionar Perfil"
            label-text="Filtrar por Perfil"
            label="description"
            class="mr-5"
            closeOnSelect
            show-label
            :multipleOption="false"
            :options="abilityProfilesForMenus"
            :value="ability_profile"
            :dispatch="setFilterAbilityProfile"
          />
        </div>
      </FilterComponent>
      <div class="mt-3">
        <a
          href="javascript:void(0)"
          @click="exportToExcel('users_spreadsheet')"
          v-if="users.length > 0 && permit_export_spreadsheet"
        >
          <img
            class="inline-block"
            src="@/assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar Planilha"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-6 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit_list"
          id="btn-consult-areas"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Users/loadUsers"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="users.length > 0">
        <a href="javascript:void(0)" @click="clearQuery()">
          <AppButtonColorful
            id="btn-consult-users"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-users">
      <div class="mx-6"><UsersCards :data="users" /></div>
      <div id="users-pagination" class="mx-12 my-6 pb-3" v-if="users.length > 0">
        <BasePagination :total="totalUsers" :total-per-page="totalPerPage" dispatch="Users/setUsersOffset" />
      </div>
      <ModalUser :open="toggleModal" />
      <ModalConfirmDeleteUser :open="modalConfirmDeleteUser" />
      <ModalUserActivation :open="modalActivation" />
      <ModalExportSpreadsheets
        :dataSpreadsheet="dataSpreadsheet"
        :modalToggleExport="modalToggleExport"
        :titleModal="titleModal"
        :dispatchToCloseModal="dispatchToCloseModal"
        :json_data="json_data"
        :sheetTabName="sheetTabName"
        :fileName="fileName"
        :variablePreloading="variablePreloading"
        :json_fields="json_fields"
        :dispatchOpenModal="dispatchOpenModal"
      />
    </div>
  </div>
</template>

<script>
import ModalUser from "./ModalUser.vue";
import UsersCards from "./list/UsersCards.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import ModalConfirmDeleteUser from "../views/ModalConfirmDeleteUser.vue";
import ModalUserActivation from "../views/ModalUserActivation.vue";
import { mapState, mapActions } from "vuex";
import BasePagination from "@/components/BasePagination.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import Label from "@/components/Label.vue";
import BaseText from "@/components/BaseText.vue";
import ModalExportSpreadsheets from "@/components/ModalExportSpreadsheets.vue";

export default {
  components: {
    ModalExportSpreadsheets,
    BaseText,
    FilterComponent,
    Label,
    Multiselect,
    BasePagination,
    UsersCards,
    ModalConfirmDeleteUser,
    ModalUserActivation,
    ModalUser,
    AppButtonColorful,
    BaseInput,
  },
  beforeCreate() {
    this.$store.dispatch("Users/loadUsers");
  },
  created() {
    this.permitCreate();
    this.permitList();
    this.permitExportSpreadsheet();
    this.$store.dispatch("UtilFunctions/loadAbilityProfilesForMenus");
    this.$store.dispatch("UtilFunctions/loadCities");
  },
  data() {
    return {
      json_fields_users: {
        "Nome do Usuário": "name",
        "E-mail": "email",
        CPF: "cpf",
        "Perfil de Acesso": "ability_profile",
        Status: "active",
        Municípios: "user_cities",
        Áreas: "user_areas",
      },
      type_export: null,
      permit_create: false,
      permit_list: false,
      permit_export_spreadsheet: false,
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.Users.users,
      city: (state) => state.Users.params.city,
      toggleModal: (state) => state.User.modalToggle,
      modalConfirmDeleteUser: (state) => state.User.modalConfirmDeleteUser,
      modalResetPassword: (state) => state.User.modalResetPassword,
      modalActivation: (state) => state.User.modalActivation,
      name: (state) => state.Users.params.name,
      email: (state) => state.Users.params.email,
      cpf: (state) => state.Users.params.cpf,
      area: (state) => state.Users.params.area,
      ability_profile: (state) => state.Users.params.ability_profile,
      status: (state) => state.Users.params.status,
      statuses: (state) => state.Users.statuses,
      offset: (state) => state.Users.usersOffset,
      totalPerPage: (state) => state.Users.usersTotalPerPage,
      totalUsers: (state) => state.Users.usersTotal,
      abilityProfilesForMenus: (state) => state.UtilFunctions.ability_profiles_for_menus,
      cities: (state) => state.City.citiesAll,
      user_areas: (state) => state.UtilFunctions.user_areas,

      users_spreadsheet: (state) => state.Users.users_spreadsheet,
      hasSpreadsheetUsersResponse: (state) => state.Users.hasSpreadsheetUsersResponse,
      modalToggleExportUsersSpreadsheet: (state) => state.Users.modalToggleExportUsersSpreadsheet,
      json_data_user: (state) => state.Users.json_data_user,

      dataSpreadsheet: (state) => state.SelectFieldsToExport.dataSpreadsheet,
      modalToggleExport: (state) => state.SelectFieldsToExport.modalToggleExport,
      titleModal: (state) => state.SelectFieldsToExport.titleModal,
      dispatchToCloseModal: (state) => state.SelectFieldsToExport.dispatchToCloseModal,
      json_data: (state) => state.SelectFieldsToExport.json_data,
      sheetTabName: (state) => state.SelectFieldsToExport.sheetTabName,
      fileName: (state) => state.SelectFieldsToExport.fileName,
      variablePreloading: (state) => state.SelectFieldsToExport.variablePreloading,
      json_fields: (state) => state.SelectFieldsToExport.json_fields,
      dispatchOpenModal: (state) => state.SelectFieldsToExport.dispatchOpenModal,
    }),
    title() {
      return {
        "text-ashes-hover": true,
      };
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setFilterAbilityProfile: "Users/setFilterAbilityProfile",
      setFilterStatus: "Users/setFilterStatus",
      setFilterCity: "Users/setFilterCity",
      setFilterArea: "Users/setFilterArea",
    }),
    permitCreate() {
      this.canAcces("user_create").then((resp) => {
        this.permit_create = resp;
      });
    },
    permitList() {
      this.canAcces("user_index").then((resp) => {
        this.permit_list = resp;
      });
    },
    permitExportSpreadsheet() {
      this.canAcces("user_export_spreadsheet").then((resp) => {
        this.permit_export_spreadsheet = resp;
      });
    },
    clearQuery() {
      this.$store.dispatch("Users/clearQuery");
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    async exportToExcel(export_type) {
      this.type_export = export_type;
      let modalOptions = {};
      switch (export_type) {
        case "users_spreadsheet":
          modalOptions = {
            title: "Exportar Planilha de Usuários",
            closeModalDispatch: "Users/closeExportUsersSpreadsheetModal",
            sheetTabName: "usuarios",
            fileName: "usuarios.xls",
            jsonFields: this.json_fields_users,
            openModalDispatch: "Users/loadUsersForExport",
          };
          break;
      }
      await this.$store.dispatch("UtilFunctions/setSelectFieldsToExportFields", modalOptions);
    },
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Users/loadUsers");
      this.backToTop();
    },
    users_spreadsheet: {
      async handler(newValue) {
        if (this.type_export === "users_spreadsheet") {
          await this.$store.dispatch("SelectFieldsToExport/setDataSpreadsheet", newValue);
        }
      },
      deep: true,
    },
    json_data_user: {
      async handler(newValue) {
        if (this.type_export === "users_spreadsheet") {
          await this.$store.dispatch("SelectFieldsToExport/setJsonData", newValue);
        }
      },
      deep: true,
    },
    modalToggleExportUsersSpreadsheet: async function () {
      if (this.type_export === "users_spreadsheet") {
        await this.$store.dispatch(
          "SelectFieldsToExport/setModalToggleExport",
          this.modalToggleExportUsersSpreadsheet
        );
      }
    },
    hasSpreadsheetUsersResponse: async function () {
      if (this.type_export === "users_spreadsheet") {
        await this.$store.dispatch(
          "SelectFieldsToExport/setVariablePreloading",
          this.hasSpreadsheetUsersResponse
        );
      }
    },
  },
};
</script>
